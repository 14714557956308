import React from "react";
import "./a_propos.css";
import imgMoi from "../../assets/moi2.png"
import StarRating from "../../components/StarRating";
import "swiper/css"; // Import des styles de base
import "swiper/css/navigation"; // Import des styles pour la navigation
import "swiper/css/pagination"; // Styles pour la pagination
import FadeInText from "../../components/FadeInText";

const APropos = () => {
  const calculateAge = (birthDate) => {
    const birth = new Date(birthDate);
    const today = new Date();
    const age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      return age - 1;
    }
    return age;
  };

  const age = calculateAge("1984-11-14");

  return (
    <main>
 <FadeInText delay={300}>
      <div className="main-container">
        {/* Section photo */}
        <div className="presentation-et-competences">

      {/* Présentation */}
      <div id="presentationQuiSuisJe">
       
          <FadeInText delay={1000}>
            <h1 style={{ textAlign: "center" }}>
              QUI SUIS-JE ? <span style={{ display: "none" }}>Bonnard Germain</span>
            </h1>
          </FadeInText>
          <FadeInText delay={1500}>
            <div className="container-photo" id="maPhoto" >
              <img
                id="photoPerso"
                src={imgMoi}
                alt="moi"
              />
            </div>
          </FadeInText>    
              
      <FadeInText delay={2000}>
        <p>
          Je m'appelle <span>Bonnard Germain</span>, j'ai {age} ans, et je suis un <span>développeur informatique</span> passionné par la technologie et l'innovation.
        </p>
      </FadeInText>          
      <FadeInText delay={2500}>
        <p>
          Mon aventure dans le développement a commencé en 2012, en autodidacte, où j'ai exploré divers langages de programmation et réalisé plusieurs projets de jeux vidéo. Cette expérience a renforcé mon goût pour la résolution de problèmes et la création de solutions numériques.
        </p>
      </FadeInText>
      <FadeInText delay={3000}>
        <p>
          Ancien musicien professionnel et professeur de flûte, j'ai décidé de suivre ma passion pour l'informatique en me reconvertissant dans ce domaine. J'ai d'abord suivi une formation de développeur logiciel de décembre 2017 à septembre 2018 à l'AFPA d'Amiens, où j'ai obtenu un diplôme bac +2 reconnu, consolidant mes bases en développement.
        </p>
      </FadeInText>
      <FadeInText delay={3500}>
        <p>
          Pour aller plus loin, j'ai poursuivi une formation de concepteur-développeur d'applications à distance avec l'école M2i Formation de novembre 2020 à juin 2021, validant un diplôme bac +4. Cette formation m'a permis d'acquérir une expertise avancée en développement logiciel.
        </p>
      </FadeInText>
      <FadeInText delay={4000}>
        <p>
          Plus récemment, j'ai enrichi mes compétences en me spécialisant dans la technologie React grâce à une formation intensive de 14 mois chez OpenClassRooms. Au cours de cette formation, j'ai mené 14 projets ambitieux, chacun validé par des soutenances devant des experts confirmés, et obtenu un titre professionnel de niveau bac +4.
        </p>
      </FadeInText>
      <FadeInText delay={4500}>
        <p>
          Aujourd'hui, en tant que micro-entrepreneur spécialisé dans la création de sites web, je mets mon expertise à votre service pour concevoir le site qui correspond parfaitement à vos besoins et à vos attentes, le tout à un tarif compétitif. Ensemble, construisons le site de vos rêves !
        </p>
      </FadeInText>
      
      
        </div>
        <FadeInText delay={5000}>
          {/* Compétences */}
          <div id="competences">
            <FadeInText delay={5500}>
              <h2>MES COMPÉTENCES</h2>
            </FadeInText>
            <FadeInText delay={6000}>
              <div className="detailsCompetence">
                <h3>HTML 5</h3>
                <StarRating ratingProp={4} readOnly={true} />
              </div>
            </FadeInText>
            <FadeInText delay={6500}>
              <div className="detailsCompetence">
                <h3>CSS 3</h3>
                <StarRating ratingProp={4} readOnly={true} />
              </div>
            </FadeInText>
            <FadeInText delay={7000}>
              <div className="detailsCompetence">
                <h3>BOOTSTRAP 4</h3>
                <StarRating ratingProp={4} readOnly={true} />
              </div>
            </FadeInText>
            <FadeInText delay={7500}>
              <div className="detailsCompetence">
                <h3>JAVASCRIPT</h3>
                <StarRating ratingProp={5} readOnly={true} />
              </div>
            </FadeInText>
            <FadeInText delay={8000}>
              <div className="detailsCompetence">
                <h3>PHP 8</h3>
                <StarRating ratingProp={5} readOnly={true} />
              </div>
            </FadeInText>
            <FadeInText delay={8500}>
              <div className="detailsCompetence">
                <h3>REACT</h3>
                <StarRating ratingProp={5} readOnly={true} />
              </div>
            </FadeInText>
            
          </div>
        
        </FadeInText>
        </div>
      </div>
      </FadeInText>

    </main>
  );
};

export default APropos;
