import { useState } from "react";
import { QRCodeCanvas } from 'qrcode.react';

function GenerateProspect() {
    const [formData, setFormData] = useState({
        typeProspect: '',
        villeProspect: '',
        departementProspect: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [prospects, setProspects] = useState([]);
    const [nbTotalProspects, setNbTotalProspects] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            // Étape 1 : Récupérer le nombre total de prospects
            const params = {
                typeEntrepriseProspect: formData.typeProspect,
                villeProspect: formData.villeProspect,
                codePostalProspect: formData.departementProspect,
                page: 1,
                limit: 1,
            };

            const queryString = new URLSearchParams(params).toString();
            const responseInitial = await fetch(`/api/prospects?${queryString}`);
            if (!responseInitial.ok) {
                throw new Error("Erreur lors de la récupération des prospects.");
            }
            const dataInitial = await responseInitial.json();

            // Enregistrer le nombre total de prospects
            const total = dataInitial.nbTotalProspects;
            setNbTotalProspects(total);

            if (total > 0) {
                // Étape 2 : Générer une page aléatoire et récupérer un prospect
                const randomPage = Math.floor(Math.random() * total) + 1;
                const randomQueryString = new URLSearchParams({
                    ...params,
                    page: randomPage, // Générer une page aléatoire
                    limit: 1, // Limiter à 1 résultat
                    timestamp: Date.now(), // Ajout pour éviter le cache
                }).toString();

                const responseRandom = await fetch(`/api/prospects?${randomQueryString}`);
                if (!responseRandom.ok) {
                    throw new Error("Erreur lors de la récupération d'un prospect.");
                }
                const dataRandom = await responseRandom.json();
                setProspects(dataRandom.prospects || []);
                localStorage.setItem('prospectSelected', JSON.stringify(prospects[0]));
            } else {
                setProspects([]);
            }
        } catch (error) {
            console.error("Erreur:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="formFilterProspect">
            <label htmlFor="typeProspect">Type Prospect :</label>
            <input
                type="text"
                id="typeProspect"
                name="typeProspect"
                value={formData.typeProspect}
                onChange={handleInputChange}
            />
            <label htmlFor="villeProspect">Ville Prospect :</label>
            <input
                type="text"
                id="villeProspect"
                name="villeProspect"
                value={formData.villeProspect}
                onChange={handleInputChange}
            />
            <label htmlFor="departementProspect">Departement Prospect :</label>
            <input
                type="text"
                id="departementProspect"
                name="departementProspect"
                value={formData.departementProspect}
                onChange={handleInputChange}
            />
            <button onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? "Chargement..." : "GÉNÉRER UN PROSPECT"}
            </button>

            {nbTotalProspects !== null && (
                <p>
                    {nbTotalProspects > 0
                        ? `${nbTotalProspects} prospects trouvés.`
                        : "Aucun prospect trouvé pour les critères donnés."}
                </p>
            )}

            {prospects && prospects.length > 0 && (
                <div className="prospectResult">
                    <h3>Prospect Généré :</h3>
                    <p>
                        <QRCodeCanvas value={`tel:${prospects[0].telephoneProspect}`} size={150} />
                    </p>
                    <p>{prospects[0].telephoneProspect}</p>
                    <p>{prospects[0].typeEntrepriseProspect}</p>
                    <p>{prospects[0].raisonSocialeProspect}</p>
                    <p>{prospects[0].villeProspect}</p>
                    
                   
                </div>
            )}
        </div>
    );
}

export default GenerateProspect;
