import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react"; // Import des composants React
import { Navigation, Pagination } from 'swiper/modules';

import "swiper/css"; // Import des styles de base
import "swiper/css/navigation"; // Import des styles pour la navigation
import "swiper/css/pagination"; // Styles pour la pagination

import "./Offres.css";
import { useNavigate } from "react-router";
import FadeInText from "../../components/FadeInText";

const Offres = () => {
  const navigate = useNavigate();
  const [activeSlideKey, setActiveSlideKey] = useState(0); // Clé pour déclencher l'animation

  return (
    <main>
      <FadeInText>
      <h1>Mes offres disponibles</h1>

      <Swiper
        
        rewind={true}
        navigation={true}
        pagination={{ clickable: true, type: 'bullets' }}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={(swiper) => setActiveSlideKey(swiper.activeIndex)} // Détecte le changement de slide
        modules={[Navigation,Pagination]} // Modules activés
      >
        
        <SwiperSlide>
          <div className="formule">
          <FadeInText triggerKey={activeSlideKey}>
            <div className="contenuOffre">
              <h1>LOCATION SITE VITRINE</h1>
              <h2>
                49€90 / Mois <br />
                Sans engagement
              </h2>
              <h3>Inclus :</h3>
              <ul>
                <li>Création du site vitrine sur mesure</li>
                <li>Mise à jour continue</li>
                <li>Nom de domaine</li>
                <li>Hébergement</li>
                <li>Référencement naturel</li>
                <li>Maintenance</li>
                <li>Bonnard Web Editions reste proprietaire du site</li>
              </ul>
              <button onClick={()=>navigate("/contact/locationVitrine")} type="button" className="btn btn-primary">
                SOUSCRIRE
              </button>
            </div>
            </FadeInText>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="formule">
            <FadeInText triggerKey={activeSlideKey}>
            <div className="contenuOffre">
              <h1>LOCATION SITE E-COMMERCE</h1>
              <h2>
                89€90 / Mois <br />
                Sans engagement
              </h2>
              <h3>Inclus :</h3>
              <ul>
                <li>Création du site e-commerce sur mesure</li>
                <li>Espace client</li>
                <li>Catalogue produits illimité</li>
                <li>Module de paiement en ligne sécurisé</li>
                <li>Mise à jour continue</li>
                <li>Nom de domaine</li>
                <li>Hébergement</li>
                <li>Référencement naturel</li>
                <li>Maintenance</li>
                <li>Bonnard Web Editions reste proprietaire du site</li>
              </ul>
              <button onClick={()=>navigate("/contact/locationEcommerce")} type="button" className="btn btn-primary">
                SOUSCRIRE
              </button>
            </div>
            </FadeInText>
            
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="formule">
          <FadeInText triggerKey={activeSlideKey}>
            <div className="contenuOffre">
              <h1>SITE VITRINE</h1>
              <h2>890€</h2>
              <h3>Inclus :</h3>
              <ul>
                <li>Création du site vitrine sur mesure</li>
                <li>Nom de domaine et Hébergement offert pendant 1 an. 9€90/mois par la suite.</li>
                <li>Référencement naturel</li>
                <li>Contenu modifiable facilement</li>
                <li>Garantie 5 ans</li>
                <li>Vous êtes proprietaire du site</li>

              </ul>
              <button onClick={()=>navigate("/contact/siteVitrine")} type="button" className="btn btn-primary">
                SOUSCRIRE
              </button>
            </div>
            </FadeInText>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="formule">
          <FadeInText triggerKey={activeSlideKey}>
            <div className="contenuOffre">
              <h1>SITE E-COMMERCE</h1>
              <h2>
                1490€
              </h2>
              <h3>Inclus :</h3>
              <ul>
                <li>Création du site e-commerce sur mesure</li>
                <li>Espace client</li>
                <li>Catalogue produits illimité</li>
                <li>Module de paiement en ligne sécurisé</li>
                <li>Nom de domaine et Hébergement offert pendant 1 an. 14€90/mois par la suite.</li>
                <li>Référencement naturel</li>
                <li>Contenu modifiable facilement</li>
                <li>Garantie 5 ans</li>
                <li>Vous êtes proprietaire du site</li>

              </ul>
              <button onClick={()=>navigate("/contact/siteEcommerce")} type="button" className="btn btn-primary">
                SOUSCRIRE
              </button>
            </div>
            </FadeInText>
          </div>
        </SwiperSlide>
        
      </Swiper>
      </FadeInText>

    </main>
  );
};

export default Offres;
