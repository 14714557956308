import SolitaireGame from "../../components/SolitaireGame"
import SnakeGame from "../../components/SnakeGame"

import { useState } from "react";
import Puzzle from "../../components/Puzzle";
import "./jeux.css"
import ColorSmash from "../../components/ColorSmash";
function Jeux(){
    const [ongletActive, setOngletActive] = useState("snake");
    return(
        <main>
             <div className="main-container">
                    <div className="dashboard">
                        <nav>
                            <h2>Mes Jeux</h2>
                            <div className="container-games-menu">
                            <p
                                className={ongletActive === "snake" ? "active" : ""}
                                onClick={() => setOngletActive("snake")}
                            >
                                SNAKE
                            </p>
                            <p
                                className={ongletActive === "solitaire" ? "active" : ""}
                                onClick={() => setOngletActive("solitaire")}
                            >
                                SOLITAIRE
                            </p>
                            <p
                                className={ongletActive === "puzzle" ? "active" : ""}
                                onClick={() => setOngletActive("puzzle")}
                            >
                                PUZZLE
                            </p>
                            <p
                                className={ongletActive === "colorSmash" ? "active" : ""}
                                onClick={() => setOngletActive("colorSmash")}
                            >
                                COLOR SMATCH
                            </p>
                            </div>
                            
                            
                        </nav>
                    </div>
                    <section>
                        {ongletActive === "snake" &&
                            <div>
                                <SnakeGame/>
                               
                            </div>
                        }
                        {ongletActive === "solitaire" &&
                            <div>
                                <SolitaireGame/>
                               
                            </div>
                        }
                        {ongletActive === "puzzle" &&
                            <div>
                                <Puzzle/>
                               
                            </div>
                        }
                        {ongletActive === "colorSmash" &&
                            <div>
                                <ColorSmash/>
                               
                            </div>
                        }
                </section>
                </div>
                
        </main>
    )
}
export default Jeux