import React, { useState, useEffect, useRef } from "react";

const randomInt = (min, max) => Math.floor(min + Math.random() * (max - min + 1));

const ColorSmash = () => {
  const canvasRef = useRef(null);
  const [blockSize, setBlockSize] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [score, setScore] = useState(0);
  const [secondsLeft, setSecondsLeft] = useState(5);
  const [billes, setBilles] = useState([]);
  const [targetBille, setTargetBille] = useState(randomInt(0, 5));
  const [billeImagesLoaded, setBilleImagesLoaded] = useState(false);
  const timerRef = useRef(null); // Ref for the timer to prevent duplicates

  const billeImages = [
    "bleu.png",
    "jaune.png",
    "vert.png",
    "violet.png",
    "rouge.png",
    "orange.png",
  ].map((fileName) => {
    const img = new Image();
    img.src = require(`./assets/${fileName}`);
    return img;
  });

  // Ensure all images are loaded
  useEffect(() => {
    let loadedImages = 0;
    billeImages.forEach((img) => {
      img.onload = () => {
        loadedImages += 1;
        if (loadedImages === billeImages.length) {
          setBilleImagesLoaded(true);
        }
      };
      img.onerror = () => {
        console.error(`Failed to load image: ${img.src}`);
      };
    });
  }, [billeImages]);

  // Adjust block size on load
  useEffect(() => {
    const resizeHandler = () => {
      const newBlockSize =
        window.innerHeight < window.innerWidth
          ? (window.innerHeight * 0.7) / 11
          : (window.innerWidth * 0.95) / 11;
      setBlockSize(newBlockSize);
    };

    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  // Refresh the canvas
  useEffect(() => {
    if (!billeImagesLoaded) return; // Wait until images are loaded

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    canvas.width = blockSize * 11;
    canvas.height = blockSize * 11;

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "#0076a8";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      if (gameStarted) {
        // Draw score
        ctx.font = `${blockSize / 2}px bold Arial`;
        ctx.fillStyle = "black";
        ctx.textAlign = "left";
        ctx.fillText(`SCORE: ${score}`, 10, blockSize * 0.75);

        // Draw target bille
        const targetImg = billeImages[targetBille];
        ctx.drawImage(
          targetImg,
          canvas.width / 2 - blockSize,
          0,
          blockSize * 2,
          blockSize * 2
        );

        // Draw billes
        billes.forEach((bille) => {
          ctx.drawImage(
            bille.image,
            bille.pos[0] * blockSize,
            bille.pos[1] * blockSize,
            blockSize,
            blockSize
          );
        });

        // Draw timer
        ctx.font = `${blockSize * 1.3}px bold Arial`;
        ctx.textAlign = "center";
        ctx.fillText(secondsLeft, canvas.width / 2, blockSize * 1.5);
      } else {
        // Draw game start screen
        ctx.font = `${blockSize * 1.5}px bold Arial`;
        ctx.textAlign = "center";
        ctx.fillText("COLOR SMASH", canvas.width / 2, blockSize * 1.5);
      }
    };

    draw();
  }, [blockSize, gameStarted, score, secondsLeft, billes, targetBille, billeImagesLoaded,billeImages]);

  const startGame = () => {
    setScore(0);
    setSecondsLeft(5);
    setGameStarted(true);
    setTargetBille(randomInt(0, 5));
    createBilles();
    startTimer();
  };

  const createBilles = () => {
    const newBilles = [];
    for (let i = 2; i < 11; i++) {
      for (let j = 1; j < 10; j++) {
        const numBille = randomInt(0, 5);
        newBilles.push({
          pos: [j, i],
          image: billeImages[numBille],
          num: numBille,
        });
      }
    }
    setBilles(newBilles);
  };

  const startTimer = () => {
    if (timerRef.current) clearInterval(timerRef.current); // Clear any existing timer

    timerRef.current = setInterval(() => {
      setSecondsLeft((prev) => {
        if (prev === 1) {
          clearInterval(timerRef.current);
          changeTargetBille();
          createBilles();
          return randomInt(3, 8);
        }
        return prev - 1;
      });
    }, 1000);
  };

  const changeTargetBille = () => {
    let newTarget;
    do {
      newTarget = randomInt(0, 5);
    } while (newTarget === targetBille);
    setTargetBille(newTarget);
    startTimer()
  };

  const handleClick = (e) => {
    if (!gameStarted) {
      startGame();
      return;
    }

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = Math.floor((e.clientX - rect.left) / blockSize);
    const y = Math.floor((e.clientY - rect.top) / blockSize);

    const clickedBilleIndex = billes.findIndex(
      (bille) => bille.pos[0] === x && bille.pos[1] === y
    );

    if (clickedBilleIndex >= 0) {
      const clickedBille = billes[clickedBilleIndex];
      if (clickedBille.num === targetBille) {
        setScore(score + 100);
        setBilles(billes.filter((_, index) => index !== clickedBilleIndex));
      } else {
        setGameStarted(false);
        alert(`Game Over! Your Score: ${score}`);
      }
    }
  };

  return (
    <div>
      {!billeImagesLoaded ? (
        <p>Loading...</p>
      ) : (
        <canvas
          ref={canvasRef}
          style={{
            border: "3px solid white",
            display: "block",
            margin: "auto",
          }}
          onClick={handleClick}
        />
      )}
    </div>
  );
};

export default ColorSmash;
