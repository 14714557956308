import React, { useState,useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import "./formContact.css";

function Contact() {
    const { preselected } = useParams(); // Récupérer le paramètre optionnel
    const navigate = useNavigate(); // Hook pour naviguer

    useEffect(() => {
        if (preselected) {
            setFormData((prevData) => ({
                ...prevData,
                offre: preselected, // Utiliser le paramètre pour pré-remplir
            }));
        }
        navigate("/contact", { replace: true }); 
    }, [preselected,navigate]);

    const [formData, setFormData] = useState({
        nom: '',
        prenom: '',
        mail: '',
        tel: '',
        offre: '',
        modeContact: '',
        disponibilites: [], // Stocke les jours et horaires sélectionnés
        message: '', // Champ pour la description du projet ou le message
    });

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [recaptchaToken, setRecaptchaToken] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCaptchaChange = (token) => {
        setRecaptchaToken(token); // Enregistre le token généré par reCAPTCHA
        setMessage(null); // Efface les messages d'erreur liés au captcha
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        script.onerror = () => {
            setMessage({ type: "error", text: "Erreur lors du chargement de reCAPTCHA." });
        };
    
        document.body.appendChild(script);
    
        return () => {
            document.body.removeChild(script); // Nettoyage
        };
    }, []);
    

    useEffect(() => {
        // Attacher la fonction handleCaptchaChange au scope global
        window.handleCaptchaChange = handleCaptchaChange;

        return () => {
            // Nettoyage : retirer la fonction du scope global
            delete window.handleCaptchaChange;
        };
    }, []);


    const handleCheckboxChange = (day, timeSlot) => {
        const newDisponibilites = [...formData.disponibilites];
        const availability = `${day} ${timeSlot}`;
        if (newDisponibilites.includes(availability)) {
            setFormData({
                ...formData,
                disponibilites: newDisponibilites.filter(item => item !== availability),
            });
        } else {
            setFormData({
                ...formData,
                disponibilites: [...newDisponibilites, availability],
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!recaptchaToken) {
            setMessage({ type: 'error', text: 'Veuillez valider le reCAPTCHA.' });
            return;
        }
        setIsLoading(true);

        const formBody = new URLSearchParams();
        for (const key in formData) {
            if (key === 'disponibilites') {
                formBody.append(key, formData[key].join(', '));
            } else if (formData[key]) {
                formBody.append(key, formData[key]);
            }
        }
        formBody.append('g-recaptcha-response', recaptchaToken); // Ajouter le token reCAPTCHA

        try {
            const response = await fetch('/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formBody.toString(),
            });

            const result = await response.json();
            if (response.ok) {
                setMessage({ type: 'success', text: 'Message envoyé avec succès ! Une réponse vous sera apportée dans les plus brefs délais.' });
                setFormData({
                    nom: '',
                    prenom: '',
                    mail: '',
                    tel: '',
                    offre: '',
                    modeContact: '',
                    disponibilites: [],
                    message: '',
                });
            } else {
                setMessage({ type: 'error', text: result.message || 'Erreur lors de l\'envoi du formulaire.' });
            }
        } catch (error) {
            setMessage({ type: 'error', text: 'Erreur réseau. Veuillez réessayer.' });
        } finally {
            setIsLoading(false);
            setRecaptchaToken(''); // Réinitialiser le token
        if (window.grecaptcha) {
            window.grecaptcha.reset(); // Réinitialiser le reCAPTCHA
        }
        }
    };

    const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'];
    const timeSlots = ['Matin (9h-12h)', 'Après-midi (14h-17h)', 'Soirée (18h-20h)'];

    return (
        <main>
<div className="formContact">
            <h1>FORMULAIRE DE CONTACT</h1>
            {message && (
                <p
                    aria-live="assertive"
                    style={{
                        color: message.type === 'success' ? 'green' : 'red',
                        marginBottom: '1rem',
                    }}
                >
                    {message.text}
                </p>
            )}
           
                <label htmlFor="nom">Nom :</label>
                <input
                    type="text"
                    id="nom"
                    name="nom"
                    value={formData.nom}
                    onChange={handleInputChange}
                    required
                />
                <label htmlFor="prenom">Prénom :</label>
                <input
                    type="text"
                    id="prenom"
                    name="prenom"
                    value={formData.prenom}
                    onChange={handleInputChange}
                    required
                />
                <label htmlFor="mail">Email :</label>
                <input
                    type="email"
                    id="mail"
                    name="mail"
                    value={formData.mail}
                    onChange={handleInputChange}
                    required
                />
                <label htmlFor="tel">Téléphone :</label>
                <input
                    type="tel"
                    id="tel"
                    name="tel"
                    value={formData.tel}
                    onChange={handleInputChange}
                />
                <label htmlFor="offre">Offre choisie :</label>
                <select
                    id="offre"
                    name="offre"
                    value={formData.offre}
                    onChange={handleInputChange}
                    required
                >
                    <option value="">-- Sélectionnez une offre --</option>
                    <option value="locationVitrine">Location site vitrine - 49€90 / mois</option>
                    <option value="locationEcommerce">Location site E-commerce - 89€90 / mois</option>
                    <option value="siteVitrine">Site vitrine - 890€</option>
                    <option value="siteEcommerce">Site E-commerce - 1490€</option>
                    <option value="autre/ne sais pas">Autre / je ne sais pas</option>


                </select>
                <fieldset>
                    <legend>Mode de contact préféré :</legend>
                    <label>
                        <input
                            type="radio"
                            name="modeContact"
                            value="Téléphone"
                            checked={formData.modeContact === 'Téléphone'}
                            onChange={handleInputChange}
                        />
                        Téléphone
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="modeContact"
                            value="Email"
                            checked={formData.modeContact === 'Email'}
                            onChange={handleInputChange}
                        />
                        Email
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="modeContact"
                            value="Visio"
                            checked={formData.modeContact === 'Visio'}
                            onChange={handleInputChange}
                        />
                        Visio
                    </label>
                </fieldset>
                <fieldset>
                    <legend>Disponibilités pour un rappel :</legend>
                    {days.map(day => (
                        <div key={day}>
                            <h4>{day}</h4>
                            {timeSlots.map(timeSlot => (
                                <label key={`${day}-${timeSlot}`}>
                                    <input
                                        type="checkbox"
                                        checked={formData.disponibilites.includes(`${day} ${timeSlot}`)}
                                        onChange={() => handleCheckboxChange(day, timeSlot)}
                                    />
                                    {timeSlot}
                                </label>
                            ))}
                        </div>
                    ))}
                </fieldset>
                <label htmlFor="message">Message / Description du projet :</label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    rows="5"
                    placeholder="Décrivez votre projet ou votre demande"
                />
                <div
                    className="g-recaptcha"
                    data-sitekey="6LcGgJ4qAAAAAEV4GB7QMZYplcShUWa1UzupC36r" // Remplacez par votre clé de site reCAPTCHA
                    data-callback="handleCaptchaChange" // Le nom de la fonction dans le scope global
                ></div>
                <button onClick={handleSubmit} disabled={isLoading}>
                    {isLoading ? 'Envoi en cours...' : 'ENVOYER'}
                </button>
            
        </div>
        </main>
        
    );
}

export default Contact;
