import React, { useEffect } from 'react';
import {Link, NavLink } from 'react-router-dom';
import imgLogo from "./assets/logo5.png"
import './header.css';
import { useAuth } from '../../context/AuthContext';
import useSessionCheck from '../UseSessionCheck';



    const Header = () => {
    const { userConnected, logout } = useAuth();
    useSessionCheck();

        useEffect(() => {
            const logo = document.getElementById('logoNavBar');
            logo.width = window.innerWidth * 0.25;
        
            const handleResize = () => {
              logo.width = window.innerWidth * 0.25;
            };
        
            window.addEventListener('resize', handleResize);
        
            return () => {
              window.removeEventListener('resize', handleResize);
            };
          }, []);
        
      return (
        <header id="header">
          {/* Logo */}
          <Link className="navbar-brand" to="/">
            <img id="logoNavBar" src={imgLogo} alt="Logo" width="100" />
          </Link>
    
          {/* Navbar */}
          <nav className="navbar navbar-expand-lg">
            <button
              className="navbar-toggler custom-toggler"
              id="nav-toggle-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
    
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto justify-content-center">
                {/* Accueil */}
                <li className="nav-item">
                  <NavLink className="nav-link" to="/">
                    ACCUEIL
                  </NavLink>
                </li>
    
                {/* Qui suis-je */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/a_propos"
                  >
                    A PROPOS
                  </NavLink>
                </li>
    
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/offres"
                  >
                    MES OFFRES
                  </NavLink>
                </li>
    
                {/* Contact */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/contact"
                   
                  >
                    CONTACT
                  </NavLink>
                </li>
    
                {/* Espace Client */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/espace_client"
                  >
                    ESPACE CLIENT
                  </NavLink>
                </li>
                {userConnected && (userConnected.roleUser === 'superAdmin' || userConnected.roleUser === 'commercial') && (
    <NavLink className="main-nav-item" to="/espace_admin">ESPACE ADMIN</NavLink>
)}
{userConnected && (
    <div className='userConnected'>
        <span>Bienvenue, {userConnected.mailUser}</span>
        <input type="button" value="DÉCONNEXION" onClick={logout} />
    </div>
)}
                
              </ul>
            </div>
          </nav>
        </header>
      );
    };
    
    export default Header;
    

