import React, { useState, useEffect, useRef, useCallback } from "react";
import "./snakeGame.css";
import ArrowController from "../ArrowController";

const SnakeGame = () => {
  const gridSize = 20;
  const speed = 100;

  const initialSnake = [[8, 8]];
  const initialDirection = [0, 1]; // Direction initiale

  const [snake, setSnake] = useState(initialSnake);
  const [direction, setDirection] = useState(initialDirection);
  const [food, setFood] = useState([10, 10]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(true);
  const gameLoop = useRef(null);

  const isFoodOnSnake = useCallback(
    (foodPosition) => {
      return snake.some(
        (segment) =>
          segment[0] === foodPosition[0] && segment[1] === foodPosition[1]
      );
    },
    [snake]
  );

  const generateFood = useCallback(() => {
    let newFood;
    do {
      newFood = [
        Math.floor(Math.random() * gridSize),
        Math.floor(Math.random() * gridSize),
      ];
    } while (isFoodOnSnake(newFood));
    return newFood;
  }, [isFoodOnSnake]);

  const moveSnake = useCallback(() => {
    const newSnake = [...snake];
    const head = newSnake[newSnake.length - 1];
    const newHead = [head[0] + direction[0], head[1] + direction[1]];

    if (
      newHead[0] < 0 ||
      newHead[0] >= gridSize ||
      newHead[1] < 0 ||
      newHead[1] >= gridSize ||
      newSnake.some(
        (segment) => segment[0] === newHead[0] && segment[1] === newHead[1]
      )
    ) {
      setGameOver(true);
      clearInterval(gameLoop.current);
      return;
    }

    newSnake.push(newHead);

    if (food && newHead[0] === food[0] && newHead[1] === food[1]) {
      setScore((prev) => prev + 1);
      setFood(generateFood());
    } else {
      newSnake.shift();
    }

    setSnake(newSnake);
  }, [direction, food, generateFood, snake]);

  // Fonction pour changer la direction à partir de l'action du parent
  const handleArrowClick = (arrowDirection) => {
    const directionMap = {
      up: [-1, 0],
      down: [1, 0],
      left: [0, -1],
      right: [0, 1],
    };

    const newDirection = directionMap[arrowDirection];

    // On ne permet pas de changer la direction en 180° (ex: de haut vers bas)
    if (
      (direction[0] === 1 && newDirection[0] === -1) ||
      (direction[0] === -1 && newDirection[0] === 1) ||
      (direction[1] === 1 && newDirection[1] === -1) ||
      (direction[1] === -1 && newDirection[1] === 1)
    ) {
      return; // Direction opposée interdite
    }

    setDirection(newDirection); // Met à jour la direction avec la nouvelle direction
  };

  const startGame = () => {
    setSnake(initialSnake);
    setDirection(initialDirection);
    setFood(generateFood());
    setScore(0);
    setGameOver(false);

    if (gameLoop.current) {
      clearInterval(gameLoop.current);
    }

    gameLoop.current = setInterval(moveSnake, speed);
  };

  // Fonction pour gérer la direction avec les touches du clavier
  const changeDirectionWithKeyboard = useCallback(
    (e) => {
      const { key } = e;
      const newDirection =
        key === "ArrowUp" && direction[0] !== 1
          ? [-1, 0]
          : key === "ArrowDown" && direction[0] !== -1
          ? [1, 0]
          : key === "ArrowLeft" && direction[1] !== 1
          ? [0, -1]
          : key === "ArrowRight" && direction[1] !== -1
          ? [0, 1]
          : direction;

      if (newDirection !== direction) {
        setDirection(newDirection);
      }
    },
    [direction]
  );

  useEffect(() => {
    window.addEventListener("keydown", changeDirectionWithKeyboard);
    return () => {
      window.removeEventListener("keydown", changeDirectionWithKeyboard);
    };
  }, [changeDirectionWithKeyboard]);

  useEffect(() => {
    if (!gameOver) {
      gameLoop.current = setInterval(moveSnake, speed);
    } else {
      clearInterval(gameLoop.current);
    }

    return () => clearInterval(gameLoop.current);
  }, [gameOver, moveSnake]);

  return (
    <div className="snake-game">
      <h1>Snake Game</h1>
      <p>Score: {score}</p>
      <div
        className="grid"
        style={{
          gridTemplateRows: `repeat(${gridSize}, 1fr)`,
          gridTemplateColumns: `repeat(${gridSize}, 1fr)`,
        }}
      >
        {Array.from({ length: gridSize * gridSize }).map((_, index) => {
          const x = Math.floor(index / gridSize);
          const y = index % gridSize;
          const isSnake = snake.some(
            (segment) => segment[0] === x && segment[1] === y
          );
          const isFood = food && food[0] === x && food[1] === y;

          return (
            <div
              key={index}
              className={`cell ${isSnake ? "snake" : ""} ${isFood ? "food" : ""}`}
            ></div>
          );
        })}
      </div>
      {gameOver && (
        <div className="game-over">
          <p>Game Over</p>
          <button onClick={startGame}>Restart</button>
        </div>
      )}
      {/* Passer la fonction handleArrowClick au composant ArrowController */}
      <ArrowController onArrowClick={handleArrowClick} onStartClick={startGame} />
    </div>
  );
};

export default SnakeGame;
