import React, { useState } from 'react';

const FormAppelProspection = () => {
    const [formData, setFormData] = useState({
        isSiteExistant: '',
        isSiteExistantResponsive: '',
        siteCreePar: "",
        coutCreationSiteExistant: '',
        isMensualiteEnCours: '',
        montantMensualite:'',
        isEngagementEnCours: '',
        dateCreationSite: "",
        typeSiteExistant: '',
        interesseParAnalyseSiteExistant: '',
        creationSiteDejaEnvisage: '',
        principalObstacleALaCreation: '',
        objectifPrincipal: '',
        budgetMaximum: '',
        budgetMensuelMaximum: '',
        preferenceAchatUniqueOuMensualite: '',
        quePensezVousTarifLocationSiteVitrine: '',
        quePensezVousTarifSiteVitrineMensualise: '',
        quePensezVousTarifSiteVitrine: '',
    });

    const [currentStep, setCurrentStep] = useState(0);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const nextStep = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const prevStep = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
    };

    const steps = [
        // Étape 1
        <div key="isSiteExistant">
            <label>Avez-vous déjà un site web ?</label><br />
            <label>
                <input
                    type="radio"
                    name="isSiteExistant"
                    value="oui"
                    checked={formData.isSiteExistant === "oui"}
                    onChange={handleChange}
                /> Oui
            </label><br />
            <label>
                <input
                    type="radio"
                    name="isSiteExistant"
                    value="non"
                    checked={formData.isSiteExistant === "non"}
                    onChange={handleChange}
                /> Non
            </label><br />
            <label>
                <input
                    type="radio"
                    name="isSiteExistant"
                    value="enCours"
                    checked={formData.isSiteExistant === "enCours"}
                    onChange={handleChange}
                /> En cours de création
            </label>
        </div>,

        // Étape 2 : Le site est-il responsive ?
        formData.isSiteExistant === 'oui' && (
            <div key="isSiteExistantResponsive">
                <label>Le site est-il responsive ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="isSiteExistantResponsive"
                        value="oui"
                        checked={formData.isSiteExistantResponsive === "oui"}
                        onChange={handleChange}
                    /> Oui
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="isSiteExistantResponsive"
                        value="non"
                        checked={formData.isSiteExistantResponsive === "non"}
                        onChange={handleChange}
                    /> Non
                </label>
            </div>
        ),

        // Étape 3 : Qui a créé le site ?
        formData.isSiteExistant === 'oui' && (
            <div key="siteCreePar">
                <label>Qui a créé le site ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="siteCreePar"
                        value="prospect"
                        checked={formData.siteCreePar === "prospect"}
                        onChange={handleChange}
                    /> Le prospect
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="siteCreePar"
                        value="freelance"
                        checked={formData.siteCreePar === "freelance"}
                        onChange={handleChange}
                    /> Un freelance
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="siteCreePar"
                        value="agence"
                        checked={formData.siteCreePar === "agence"}
                        onChange={handleChange}
                    /> Une agence
                </label>
            </div>
        ),

        // Étape 4 : Coût de création du site
        formData.isSiteExistant === 'oui' && (
            <div key="coutCreationSiteExistant">
                <label>Coût de création du site :</label><br />
                <label>
                    <input
                        type="radio"
                        name="coutCreationSiteExistant"
                        value="moins500"
                        checked={formData.coutCreationSiteExistant === "moins500"}
                        onChange={handleChange}
                    /> Moins de 500€
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="coutCreationSiteExistant"
                        value="entre500et1000"
                        checked={formData.coutCreationSiteExistant === "entre500et1000"}
                        onChange={handleChange}
                    /> Entre 500 et 1000€
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="coutCreationSiteExistant"
                        value="plus2000"
                        checked={formData.coutCreationSiteExistant === "plus2000"}
                        onChange={handleChange}
                    /> Plus de 2000€
                </label>
            </div>
        ),

        // Étape 4 : Coût de création du site
        formData.isSiteExistant === 'oui' && (
            <div key="isMensualiteEnCours">
                <label>Payez vous votre site tout les mois ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="isMensualiteEnCours"
                        value="oui"
                        checked={formData.isMensualiteEnCours === "oui"}
                        onChange={handleChange}
                    /> Oui
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="isMensualiteEnCours"
                        value="non"
                        checked={formData.isMensualiteEnCours === "non"}
                        onChange={handleChange}
                    /> Non
                </label>
            </div>
        ),

        formData.isSiteExistant ==="oui" && formData.isMensualiteEnCours ==="oui" && (
            <div key="montantMensualite">
                <label>Coût de la mensualité en cours ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="montantMensualite"
                        value="moins20"
                        checked={formData.montantMensualite === "moins20"}
                        onChange={handleChange}
                    /> Moins de 20€
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="montantMensualite"
                        value="entre20Et50"
                        checked={formData.montantMensualite === "entre20Et50"}
                        onChange={handleChange}
                    /> Entre 20 et 50€
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="montantMensualite"
                        value="plus50"
                        checked={formData.montantMensualite === "plus50"}
                        onChange={handleChange}
                    /> Plus de 50€
                </label>
            </div>
        ),
        formData.isSiteExistant ==="oui" && formData.isMensualiteEnCours ==="oui" && (
            <div key="isEngagementEnCours">
                <label>Etes vous toujours engagé avec votre ancien prestataire ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="isEngagementEnCours"
                        value="oui"
                        checked={formData.isEngagementEnCours === "oui"}
                        onChange={handleChange}
                    /> Oui
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="isEngagementEnCours"
                        value="non"
                        checked={formData.isEngagementEnCours === "non"}
                        onChange={handleChange}
                    /> Non
                </label>
            </div>
        ),
        formData.isSiteExistant === 'oui' && (
            <div key="dateCreationSite">
                <label>Votre site existe depuis combien de temps ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="dateCreationSite"
                        value="moins1an"
                        checked={formData.dateCreationSite === "moins1an"}
                        onChange={handleChange}
                    /> Moins de 1 an
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="dateCreationSite"
                        value="entre1et3ans"
                        checked={formData.dateCreationSite === "entre1et3ans"}
                        onChange={handleChange}
                    /> Entre 1 et 3 ans
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="dateCreationSite"
                        value="entre3et5ans"
                        checked={formData.dateCreationSite === "entre3et5ans"}
                        onChange={handleChange}
                    /> Entre 3 et 5 ans
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="dateCreationSite"
                        value="plus5ans"
                        checked={formData.dateCreationSite === "plus5ans"}
                        onChange={handleChange}
                    /> Plus de 5 ans
                </label>
            </div>
        ),

        formData.isSiteExistant === 'oui' && (
            <div key="typeSiteExistant">
                <label>Quel genre de site avez vous ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="typeSiteExistant"
                        value="vitrine"
                        checked={formData.typeSiteExistant === "vitrine"}
                        onChange={handleChange}
                    /> Site vitrine
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="typeSiteExistant"
                        value="eCommerce"
                        checked={formData.typeSiteExistant === "eCommerce"}
                        onChange={handleChange}
                    /> Site e-commerce
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="typeSiteExistant"
                        value="blog"
                        checked={formData.typeSiteExistant === "blog"}
                        onChange={handleChange}
                    /> Blog
                </label>
            </div>
        ),
        formData.isSiteExistant === 'oui' && (
            <div key="interesseParAnalyseSiteExistant">
                <label>Etes vous interessé par une petite analyse gratuite et sans engagement de votre site existant ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="interesseParAnalyseSiteExistant"
                        value="oui"
                        checked={formData.interesseParAnalyseSiteExistant === "oui"}
                        onChange={handleChange}
                    /> Oui
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="interesseParAnalyseSiteExistant"
                        value="non"
                        checked={formData.interesseParAnalyseSiteExistant === "non"}
                        onChange={handleChange}
                    /> Non
                </label>
            </div>
        ),
        // Étape 5 : Envisager de créer un site ?
        formData.isSiteExistant === 'non' && (
            <div key="creationSiteDejaEnvisage">
                <label>Avez-vous déjà envisagé de créer un site ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="creationSiteDejaEnvisage"
                        value="oui"
                        checked={formData.creationSiteDejaEnvisage === "oui"}
                        onChange={handleChange}
                    /> Oui
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="creationSiteDejaEnvisage"
                        value="non"
                        checked={formData.creationSiteDejaEnvisage === "non"}
                        onChange={handleChange}
                    /> Non
                </label>
            </div>
        ),

        // Étape 6 : Freins à la création
        formData.isSiteExistant === 'non' && (
            <div key="principalObstacleALaCreation">
                <label>Quel est votre principal obstacle à la création ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="principalObstacleALaCreation"
                        value="argent"
                        checked={formData.principalObstacleALaCreation === "argent"}
                        onChange={handleChange}
                    /> Manque d'argent
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="principalObstacleALaCreation"
                        value="temps"
                        checked={formData.principalObstacleALaCreation === "temps"}
                        onChange={handleChange}
                    /> Manque de temps
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="principalObstacleALaCreation"
                        value="connaissances"
                        checked={formData.principalObstacleALaCreation === "connaissances"}
                        onChange={handleChange}
                    /> Manque de connaissances techniques
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="principalObstacleALaCreation"
                        value="utilite"
                        checked={formData.principalObstacleALaCreation === "utilite"}
                        onChange={handleChange}
                    /> Manque d'utilité
                </label>

            </div>
        ),
        formData.isSiteExistant === 'non' && (
            <div key="objectifPrincipal">
                <label>Quel est l'objectif principal que vous souhaitez atteindre avec un site web ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="objectifPrincipal"
                        value="visibilite"
                        checked={formData.objectifPrincipal === "visibilite"}
                        onChange={handleChange}
                    /> Augmenter la visibilité et l’acquisition de nouveaux clients

                </label><br />
                <label>
                    <input
                        type="radio"
                        name="objectifPrincipal"
                        value="vendreEnLigne"
                        checked={formData.objectifPrincipal === "vendreEnLigne"}
                        onChange={handleChange}
                    /> Vendre en ligne
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="objectifPrincipal"
                        value="informer"
                        checked={formData.objectifPrincipal === "connaissances"}
                        onChange={handleChange}
                    /> Informer les visiteurs sur votre entreprise (horaires,localisation, services, produits)
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="objectifPrincipal"
                        value="image"
                        checked={formData.objectifPrincipal === "image"}
                        onChange={handleChange}
                    /> Renforcer la crédibilité et l’image de marque
                </label>

            </div>
        ),
        formData.isSiteExistant === 'non' && (
            <div key="objectifPrincipal">
                <label>Quel est l'objectif principal que vous souhaitez atteindre avec un site web ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="objectifPrincipal"
                        value="visibilite"
                        checked={formData.objectifPrincipal === "visibilite"}
                        onChange={handleChange}
                    /> Augmenter la visibilité et l’acquisition de nouveaux clients

                </label><br />
                <label>
                    <input
                        type="radio"
                        name="objectifPrincipal"
                        value="vendreEnLigne"
                        checked={formData.objectifPrincipal === "vendreEnLigne"}
                        onChange={handleChange}
                    /> Vendre en ligne
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="objectifPrincipal"
                        value="informer"
                        checked={formData.objectifPrincipal === "connaissances"}
                        onChange={handleChange}
                    /> Informer les visiteurs sur votre entreprise (horaires,localisation, services, produits)
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="objectifPrincipal"
                        value="image"
                        checked={formData.objectifPrincipal === "image"}
                        onChange={handleChange}
                    /> Renforcer la crédibilité et l’image de marque
                </label>

            </div>
        ),
        formData.isSiteExistant === 'non' && (
            <div key="budgetMaximum">
                <label>Quel est le budget maximum que vous pourriez accorder au développement d'un site web ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="budgetMaximum"
                        value="moins500"
                        checked={formData.budgetMaximum === "moins500"}
                        onChange={handleChange}
                    /> Moins de 500€

                </label><br />
                <label>
                    <input
                        type="radio"
                        name="budgetMaximum"
                        value="entre500et1000"
                        checked={formData.budgetMaximum === "entre500et1000"}
                        onChange={handleChange}
                    /> Entre 500 et 1000€
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="budgetMaximum"
                        value="entre1000et2000"
                        checked={formData.budgetMaximum === "entre1000et2000"}
                        onChange={handleChange}
                    /> Entre 1000 et 2000€
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="budgetMaximum"
                        value="plus2000"
                        checked={formData.budgetMaximum === "plus2000"}
                        onChange={handleChange}
                    /> Plus de 2000€
                </label><br />

            </div>
        ),
        formData.isSiteExistant === 'non' && (
            <div key="budgetMensuelMaximum">
                <label>Quel est le budget maximum mensuel que vous pourriez accorder au développement d'un site vitrine ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="budgetMensuelMaximum"
                        value="moins30"
                        checked={formData.budgetMensuelMaximum === "moins30"}
                        onChange={handleChange}
                    /> Moins de 30€

                </label><br />
                <label>
                    <input
                        type="radio"
                        name="budgetMensuelMaximum"
                        value="entre30et50"
                        checked={formData.budgetMensuelMaximum === "entre30et50"}
                        onChange={handleChange}
                    /> Entre 30 et 50€
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="budgetMensuelMaximum"
                        value="entre50et100"
                        checked={formData.budgetMensuelMaximum === "entre50et100"}
                        onChange={handleChange}
                    /> Entre 50 et 100€
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="budgetMensuelMaximum"
                        value="plus100"
                        checked={formData.budgetMensuelMaximum === "plus100"}
                        onChange={handleChange}
                    /> Plus de 100€
                </label><br />

            </div>
        ),
        formData.isSiteExistant ==="non" && (
            <div key="preferenceAchatUniqueOuMensualite">
                <label>Vous préférez un paiement en une fois ou payer tout les mois ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="preferenceAchatUniqueOuMensualite"
                        value="paiementUnique"
                        checked={formData.preferenceAchatUniqueOuMensualite === "paiementUnique"}
                        onChange={handleChange}
                    /> Paiement unique
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="preferenceAchatUniqueOuMensualite"
                        value="mensualite"
                        checked={formData.preferenceAchatUniqueOuMensualite === "mensualite"}
                        onChange={handleChange}
                    /> Paiement par mensualités
                </label>
            </div>
        ),
        (
            <div key="quePensezVousTarifLocationSiteVitrine">
                <label>Que pensez vous de 49€90 / mois sans engagement pour la création et la location d'un site vitrine ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="quePensezVousTarifLocationSiteVitrine"
                        value="tresAbordable"
                        checked={formData.quePensezVousTarifLocationSiteVitrine === "tresAbordable"}
                        onChange={handleChange}
                    /> Très abordable
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="quePensezVousTarifLocationSiteVitrine"
                        value="abordable"
                        checked={formData.quePensezVousTarifLocationSiteVitrine === "abordable"}
                        onChange={handleChange}
                    /> Abordable
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="quePensezVousTarifLocationSiteVitrine"
                        value="tropCher"
                        checked={formData.quePensezVousTarifLocationSiteVitrine === "tropCher"}
                        onChange={handleChange}
                    /> Trop cher
                </label>
            </div>
        ),
        (
            <div key="quePensezVousTarifSiteVitrineMensualise">
                <label>Que pensez vous de 49€90 / mois pendant 2 ans pour la création et l'achat d'un site vitrine ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="quePensezVousTarifSiteVitrineMensualise"
                        value="tresAbordable"
                        checked={formData.quePensezVousTarifSiteVitrineMensualise === "tresAbordable"}
                        onChange={handleChange}
                    /> Très abordable
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="quePensezVousTarifSiteVitrineMensualise"
                        value="abordable"
                        checked={formData.quePensezVousTarifSiteVitrineMensualise === "abordable"}
                        onChange={handleChange}
                    /> Abordable
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="quePensezVousTarifSiteVitrineMensualise"
                        value="tropCher"
                        checked={formData.quePensezVousTarifSiteVitrineMensualise === "tropCher"}
                        onChange={handleChange}
                    /> Trop cher
                </label>
            </div>
        ),
        (
            <div key="quePensezVousTarifSiteVitrine">
                <label>Que pensez vous de 890€ pour la création et l'achat d'un site vitrine avec nom de domaine et hebergement offert pendant 1 an (39€90 par an par la suite)  ?</label><br />
                <label>
                    <input
                        type="radio"
                        name="quePensezVousTarifSiteVitrine"
                        value="tresAbordable"
                        checked={formData.quePensezVousTarifSiteVitrine === "tresAbordable"}
                        onChange={handleChange}
                    /> Très abordable
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="quePensezVousTarifSiteVitrine"
                        value="abordable"
                        checked={formData.quePensezVousTarifSiteVitrine === "abordable"}
                        onChange={handleChange}
                    /> Abordable
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="quePensezVousTarifSiteVitrine"
                        value="tropCher"
                        checked={formData.quePensezVousTarifSiteVitrine === "tropCher"}
                        onChange={handleChange}
                    /> Trop cher
                </label>
            </div>
        ),
    ].filter(Boolean);

    return (
        <div>
            <h2>Formulaire Appel Prospection</h2>
            <form>
                {steps[currentStep]}

                <div style={{ marginTop: '20px' }}>
                    {currentStep > 0 && (
                        <button type="button" onClick={prevStep}>
                            Précédent
                        </button>
                    )}
                    {currentStep < steps.length - 1 && (
                        <button type="button" onClick={nextStep}>
                            Suivant
                        </button>
                    )}
                    {currentStep === steps.length - 1 && (
                        <button type="submit">
                            Soumettre
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default FormAppelProspection;


