import { useState } from 'react';
import { useAuth } from '../../context/AuthContext'
import FormAppelProspection from '../FormAppelProspection'

const FormAppel = () => {
    const { userConnected } = useAuth();
    const { appelStarted, setAppelStarted } = useState(false)
    const [currentStep, setCurrentStep] = useState(0);
    const [isResponsableDispo, setIsResponsableDispo] = useState(false);

    const stepsFormAppel = [
        (
            <div>
                <button onClick={() => handleStartAppel()} disabled={appelStarted}>Début de l'appel</button>
            </div>
        ),
        (
            <div>
                <p>
                    Bonjour, {userConnected.nomUser} {userConnected.prenomUser} de Bonnard Web Editions, Je mène actuellement une petite enquête auprès des entreprises des Hauts-de-France sur leur présence en ligne. Est-ce vous qui dirigez l’entreprise ?
                </p>
                <p>
                    Si oui : <br />
                    Super ! J’aimerais vous poser quelques questions rapides, cela ne prendra pas plus de 5 minutes. Cela vous convient ?
                </p>
                <p>
                    Si non : <br />
                    Pourriez-vous m’indiquer la personne à contacter à ce sujet, s’il vous plaît ? il/elle est disponible ?
                    Si disponible et au tel reprendre la phrase d'intro
                </p>
                <div>
                    <button onClick={()=>{
                        setIsResponsableDispo(true)
                        nextStep()
                    }}>dispo et ok pour répondre</button>
                    <button onClick={()=>{
                        setIsResponsableDispo(false)
                        nextStep()
                    }}>indisponible actuellement</button>
                </div>
                
            </div>
        ),
        isResponsableDispo&&(
            <div>
                <FormAppelProspection/>
            </div>
        ),
        !isResponsableDispo&&(
            <div>
                <FormAppelProspection/>
            </div>
        ),


    ].filter(Boolean)
    const nextStep = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const prevStep = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
    };
    const handleStartAppel = async (e) => {
        const prospectSelected = localStorage.getItem('prospectSelected') 
            ? JSON.parse(localStorage.getItem('prospectSelected')) 
            : null;
    
        if (!prospectSelected || !prospectSelected.idProspect) {
            console.error("Aucun prospect sélectionné");
            return;
        }
    
        const formData = new FormData();
        formData.append("idProspect", prospectSelected.idProspect);
        formData.append("idUser", userConnected.idUser);
    
        try {
            const response = await fetch('/api/prospection/appel/start', {
                method: 'POST',
                body: formData, 
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log('Nouvel appel commencé :', data);
                setAppelStarted(true)
                nextStep()
            } else {
                console.error('Erreur lors du démarrage de l\'appel :', response.statusText);
            }
        } catch (error) {
            console.error('Erreur de réseau :', error);
        }
    };
    
        return (
            <div>
                
                {stepsFormAppel[currentStep]}
                <div style={{ marginTop: '20px' }}>
                    {currentStep > 0 && (
                        <button type="button" onClick={prevStep}>
                            Précédent
                        </button>
                    )}
                    {currentStep < stepsFormAppel.length - 1 && (
                        <button type="button" onClick={nextStep}>
                            Suivant
                        </button>
                    )}
                    {currentStep === stepsFormAppel.length - 1 && (
                        <button type="submit">
                            Soumettre
                        </button>
                    )}
                </div>
            </div>
        
        )
    }
    

export default FormAppel