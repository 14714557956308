import React from 'react';
import { Outlet } from 'react-router-dom';
import HeaderSalonCoiffure1 from '../HeaderSalonCoiffure1';
import Footer from '../../../../../components/Footer';

function SalonCoiffureLayout() {
  return (
    <div className="container-fluid">
      <HeaderSalonCoiffure1 />
      {/* Outlet rendra les composants enfants ici */}
      <Outlet />
      <Footer />
    </div>
  );
}

export default SalonCoiffureLayout;
