import React from "react";
import "./ArrowController.css";
import { FaPlay } from "react-icons/fa";

const ArrowController = ({ onArrowClick, onStartClick }) => {
  const handleClick = (direction) => {
    // Appel de la fonction passée par le parent avec la direction comme argument
    if (onArrowClick) {
      onArrowClick(direction);
    }
  };

  const handleStartClick = () => {
    // Appel de la fonction de démarrage si définie
    if (onStartClick) {
      onStartClick();
    }
  };

  return (
    <div className="controller">
      <div
        className="arrow up"
        onClick={() => handleClick("up")}
      >
        ↑
      </div>
      <div
        className="arrow left"
        onClick={() => handleClick("left")}
      >
        ←
      </div>
      <div className="center" onClick={handleStartClick}>
        <FaPlay className="play-icon" />
      </div>
      <div
        className="arrow right"
        onClick={() => handleClick("right")}
      >
        →
      </div>
      <div
        className="arrow down"
        onClick={() => handleClick("down")}
      >
        ↓
      </div>
    </div>
  );
};

export default ArrowController;
