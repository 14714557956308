import Column from '../Column'
import './DataTable.css'
import React, { useState, useEffect } from 'react'

function DataTable({ data, columns, onUpdateData }) {
  const [editingCell, setEditingCell] = useState(null); // Cellule en cours de modification
  const [editedValue, setEditedValue] = useState(''); // Valeur modifiée de la cellule
  const [searchTerm, setSearchTerm] = useState('');
  const [currentSort, setCurrentSort] = useState({ key: null, direction: null });
  const [nbResultByPage, setNbResultByPage] = useState(10);
  const [pageActive, setPageActive] = useState(1);

  const handleSearchTermChange = (value) => {
    setSearchTerm(value);
    setPageActive(1);
  };

  const handleCellClick = (rowIndex, column) => {
    const item = data[rowIndex];
    setEditingCell({ rowId: item.idUser, columnData: column.data });
    setEditedValue(item[column.data]);
  };

  const handleInputChange = (e) => {
    setEditedValue(e.target.value);
  };

  const handleSave = () => {
    if (editingCell) {
      const updatedRow = { ...data.find((item) => item.idUser === editingCell.rowId) };
      updatedRow[editingCell.columnData] = editedValue;

      // Appeler la fonction onUpdateData avec la ligne modifiée
      onUpdateData(updatedRow);

      // Quitter le mode édition
      setEditingCell(null);
      setEditedValue('');
    }
  };

  const calculateResultsRange = () => {
    const firstIndex = (pageActive - 1) * nbResultByPage + 1;
    const lastIndex = Math.min(pageActive * nbResultByPage, filteredData.length);
    return `${firstIndex} to ${lastIndex} of ${filteredData.length} entries`;
  };

  const filterData = (item) => {
    const searchTerms = Object.values(item).map((value) => value.toString().toLowerCase());
    return searchTerms.some((term) => term.includes(searchTerm.toLowerCase()));
  };

  const filteredData = data.filter(filterData);

  const sortedData = [...filteredData].sort((a, b) => {
    if (currentSort.direction === 'asc') {
      return a[currentSort.key] > b[currentSort.key] ? 1 : -1;
    } else if (currentSort.direction === 'desc') {
      return a[currentSort.key] < b[currentSort.key] ? 1 : -1;
    } else {
      return 0;
    }
  });

  const totalPages = Math.ceil(filteredData.length / nbResultByPage);
  const paginatedData = sortedData.slice((pageActive - 1) * nbResultByPage, pageActive * nbResultByPage);

  const handlePageChange = (newPage) => {
    setPageActive(newPage);
  };

  useEffect(() => {
    // Si une ligne est en édition, on s'assure qu'elle soit retrouvée après chaque changement de pagination ou tri
    if (editingCell) {
      const rowIndex = paginatedData.findIndex(item => item.idUser === editingCell.rowId);
      if (rowIndex === -1) {
        setEditingCell(null); // Si la ligne n'est pas dans la page actuelle, annuler l'édition
      }
    }
  }, [paginatedData, editingCell]); // Ajout de 'editingCell' dans les dépendances pour que l'effet se déclenche à chaque changement de cette valeur.

  return (
    <div className="container">
      <form>
        <div className="inputSearch">
          <label htmlFor="search">Search:</label>
          <input type="text" id="search" value={searchTerm} onChange={(e) => handleSearchTermChange(e.target.value)} />
        </div>
        <p>
          Show
          <select onChange={(e) => setNbResultByPage(Number(e.target.value))} value={nbResultByPage}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          results per page
        </p>
      </form>

      {filteredData.length > 0 ? (
        <div>
          <table>
            <thead>
              <tr>
                {columns.map((column) => (
                  <Column
                    key={column.data}
                    title={column.title}
                    data={column.data}
                    currentSort={currentSort.key === column.data ? currentSort.direction : null}
                    onSort={(key, direction) => setCurrentSort({ key, direction })}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item, rowIndex) => (
                <tr key={item.idUser}>
                  {columns.map((column, colIndex) => (
                    <td key={`${rowIndex}-${colIndex}`} onClick={() => handleCellClick(rowIndex, column)}>
                      {editingCell && editingCell.rowId === item.idUser && editingCell.columnData === column.data ? (
                        <div>
                          <input
                            type="text"
                            value={editedValue}
                            onChange={handleInputChange}
                            onBlur={handleSave} // Sauvegarde lorsque l'utilisateur sort du champ
                          />
                        </div>
                      ) : (
                        item[column.data]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <div className="infos-results">
              <p>{`Showing ${calculateResultsRange()}`}</p>
            </div>
            <div className="infos-pagination">
              <p>
                Page {pageActive} of {totalPages}
              </p>
              {totalPages > 1 && (
                <div className="controls-pagination">
                  <button onClick={() => handlePageChange(pageActive - 1)} disabled={pageActive === 1}>
                    Previous
                  </button>
                  <select name="pageActive" value={pageActive} onChange={(e) => setPageActive(Number(e.target.value))}>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                  <button onClick={() => handlePageChange(pageActive + 1)} disabled={pageActive === totalPages}>
                    Next
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p>No results found.</p>
        </div>
      )}
    </div>
  );
}

export default DataTable;
