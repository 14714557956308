import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import "./formConnexion.css";

function FormConnexion({ title }) {
    const { login } = useAuth();
    const [mailUser, setMailUser] = useState("");
    const [passwordUser, setPasswordUser] = useState("");
    const [isLoading, setIsLoading] = useState(false); // État de chargement
    const [errorMessage, setErrorMessage] = useState(""); // État pour afficher les erreurs

    const handleConnexion = async () => {
        setIsLoading(true); // Démarrer le chargement
        setErrorMessage(""); // Réinitialiser les messages d'erreur

        const data = new URLSearchParams();
        data.append('mailUser', mailUser);
        data.append('passwordUser', passwordUser);

        try {
            const response = await fetch('/api/user/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: data,
            });

            const result = await response.json();

            if (result.connection === "OK") {
                login(result.userConnected); 
            } else {
                setErrorMessage("Connexion refusée. Vérifiez vos identifiants.");
            }
        } catch (error) {
            console.error("Erreur lors de la connexion:", error);
            setErrorMessage("Une erreur est survenue. Veuillez réessayer plus tard.");
        } finally {
            setIsLoading(false); // Arrêter le chargement
        }
    };

    return (
        <div className="formConnexion">
            <h1>{title}</h1>
            {errorMessage && <p className="error">{errorMessage}</p>}
            <label htmlFor="mailUser">EMAIL</label>
            <input
                type="email"
                name="mailUser"
                id="mailUser"
                value={mailUser}
                onChange={(e) => setMailUser(e.target.value)}
            />
            <label htmlFor="passwordUser">MOT DE PASSE</label>
            <input
                type="password"
                name="passwordUser"
                id="passwordUser"
                value={passwordUser}
                onChange={(e) => setPasswordUser(e.target.value)}
            />
            <button onClick={handleConnexion} disabled={isLoading}>
                {isLoading ? "Connexion..." : "CONNEXION"}
            </button>
        </div>
    );
}

export default FormConnexion;
