import React, { useState, useEffect, useRef, useCallback } from "react";
import imgBille from "../../assets/games/solitaire/billeSolitaire.png";
import imgBilleSelected from "../../assets/games/solitaire/billeSolitaire2.png";
import imgTrou from "../../assets/games/solitaire/trou.png";
import imgPlateau from "../../assets/games/solitaire/plateau.png"
import "./SolitaireGame.css";

const SolitaireGame = () => {
    const gridSize = 9;
    const blockSize = 60;
    const canvasRef = useRef(null);

    const initialGrid = [
        [-1, -1, -1, -1, -1, -1, -1,-1,-1],
        [-1,-1, -1, 1, 1, 1, -1, -1,-1],
        [-1,-1, -1, 1, 1, 1, -1, -1,-1],
        [-1,1, 1, 1, 1, 1, 1, 1,-1],
        [-1,1, 1, 1, 0, 1, 1, 1,-1],
        [-1,1, 1, 1, 1, 1, 1, 1,-1],
        [-1,-1, -1, 1, 1, 1, -1, -1,-1],
        [-1,-1, -1, 1, 1, 1, -1, -1,-1],
        [-1,-1, -1, -1, -1, -1, -1, -1,-1],

    ];

    const [grid, setGrid] = useState(initialGrid);
    const [billeSelect, setBilleSelect] = useState(false);
    const [posBilleSelect, setPosBilleSelect] = useState(null);
    const [nbBilles, setNbBilles] = useState(32);
    const [gameOver, setGameOver] = useState(false);

    const imageAssets = useRef({});

    const refreshCanvas = useCallback(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(imageAssets.current.plateau,0,0,blockSize*gridSize,blockSize*gridSize)
        grid.forEach((row, i) => {
            row.forEach((cell, j) => {
                if (cell === -1) return;
                const image =
                    cell === 1
                        ? imageAssets.current.bille
                        : cell === 2
                        ? imageAssets.current.billeSelect
                        : imageAssets.current.trou;
                ctx.drawImage(image, j * blockSize, i * blockSize, blockSize, blockSize);
            });
        });
    }, [grid]);

    useEffect(() => {
        const images = {
            plateau: imgPlateau,
            trou: imgTrou,
            bille: imgBille,
            billeSelect: imgBilleSelected,
        };

        const loadImages = async () => {
            const imgKeys = Object.keys(images);
            const imgPromises = imgKeys.map((key) => {
                const img = new Image();
                img.src = images[key];
                return new Promise((resolve) => {
                    img.onload = () => {
                        imageAssets.current[key] = img;
                        resolve();
                    };
                });
            });

            await Promise.all(imgPromises);
            refreshCanvas();
        };

        loadImages();
    }, [refreshCanvas]);

    const checkValidMove = useCallback(
        (y, x, direction) => {
            const [py, px] = posBilleSelect || [];
            if (direction === "droite" && x === px + 2 && grid[y][x - 1] === 1 && grid[y][x] === 0)
                return true;
            if (direction === "gauche" && x === px - 2 && grid[y][x + 1] === 1 && grid[y][x] === 0)
                return true;
            if (direction === "haut" && y === py - 2 && grid[y + 1][x] === 1 && grid[y][x] === 0)
                return true;
            if (direction === "bas" && y === py + 2 && grid[y - 1][x] === 1 && grid[y][x] === 0)
                return true;
            return false;
        },
        [grid, posBilleSelect]
    );

    const handleMove = (y, x, direction) => {
        const [py, px] = posBilleSelect;
        const newGrid = grid.map((row) => [...row]);

        newGrid[y][x] = 1; // Déplacer la bille
        newGrid[py][px] = 0; // Vider la position initiale
        if (direction === "droite") newGrid[y][x - 1] = 0;
        if (direction === "gauche") newGrid[y][x + 1] = 0;
        if (direction === "haut") newGrid[y + 1][x] = 0;
        if (direction === "bas") newGrid[y - 1][x] = 0;

        setGrid(newGrid); // Mettre à jour la grille
        setBilleSelect(false);
        setPosBilleSelect(null);
    };

    const checkGameOver = useCallback(() => {
        let isGameOver = true;
        let remainingBilles = 0;
        console.log(grid)
        for (let y = 0; y < gridSize; y++) {
            for (let x = 0; x < gridSize; x++) {
                if (grid[y][x] === 1 || grid[y][x]===2) {
                    remainingBilles++;
                    if (
                        (x + 2 < 7 && grid[y][x + 1] === 1 && grid[y][x + 2] === 0) || // Droite
                        (x - 2 >= 0 && grid[y][x - 1] === 1 && grid[y][x - 2] === 0) || // Gauche
                        (y + 2 < 7 && grid[y + 1][x] === 1 && grid[y + 2][x] === 0) || // Bas
                        (y - 2 >= 0 && grid[y - 1][x] === 1 && grid[y - 2][x] === 0) // Haut
                    ) {
                        isGameOver = false;
                    }
                }
            }
        }
    
        setNbBilles(remainingBilles);
        setGameOver(isGameOver);
    }, [grid]);
    
    useEffect(() => {
        checkGameOver();
    }, [grid, checkGameOver]); // Ajout de `checkGameOver` comme dépendance

    const resetGame = () => {
        setGrid(initialGrid);
        setBilleSelect(false);
        setPosBilleSelect(null);
        setNbBilles(32);
        setGameOver(false);
    };

    const handleCanvasClick = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = Math.floor((e.clientX - rect.left) / blockSize);
        const y = Math.floor((e.clientY - rect.top) / blockSize);

        if (grid[y][x] === 1 && !billeSelect && !gameOver) {
            setBilleSelect(true);
            setPosBilleSelect([y, x]);
            updateCell(y, x, 2);
        } else if (billeSelect) {
            const direction =
                x > posBilleSelect[1] ? "droite" :
                x < posBilleSelect[1] ? "gauche" :
                y > posBilleSelect[0] ? "bas" : "haut";

            if (checkValidMove(y, x, direction)) {
                handleMove(y, x, direction);
            } else {
                updateCell(posBilleSelect[0], posBilleSelect[1], 1);
                setBilleSelect(false);
            }
        }
    };

    const updateCell = (y, x, value) => {
        setGrid((prevGrid) => {
            const newGrid = prevGrid.map((row) => [...row]);
            newGrid[y][x] = value;
            return newGrid;
        });
    };

    return (
        <div className="gameInfos">
            <h1>Jeu de Solitaire</h1>
            <p>Nombre de billes restantes : {nbBilles}</p>
            {gameOver && <p>Partie terminée !</p>}
            <canvas
                ref={canvasRef}
                width={blockSize * gridSize}
                height={blockSize * gridSize}
                onClick={handleCanvasClick}
                style={{ border: "2px solid black", display: "block", margin: "auto" }}
            ></canvas>
            <button onClick={resetGame} style={{ marginTop: "20px" }}>
                Redémarrer
            </button>
        </div>
    );
};

export default SolitaireGame;
