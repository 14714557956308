import React from 'react'
import {createRoot} from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import APropos from './pages/APropos'
import './index.css'
import Prospection from './pages/Prospection'
import { AuthProvider } from './context/AuthContext'
import PrivateRoute from './components/PrivateRoute';
import HomeSalonCoiffure1 from './sites/salonsCoiffures/demo1/pages/HomeSalonCoiffure1'
import SalonCoiffureLayout from './sites/salonsCoiffures/demo1/components/SalonCoiffure1Layout'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import EspacaceClient from './pages/EspaceClient'
import EspaceAdmin from './pages/EspaceAdmin'
import Contact from './pages/Contact'
import Jeux from './pages/Jeux'
import Offres from './pages/Offres'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={
          <div className="container-fluid">
            <Header />
            <Home />
            <Footer />
          </div>
        } />
        <Route path="/a_propos" element={
          <div className="container-fluid">
            <Header />
            <APropos />
            <Footer />
          </div>
          } />
          <Route path="/offres" element={
          <div className="container-fluid">
            <Header />
            <Offres />
            <Footer />
          </div>
          } />
          <Route path="/contact/:preselected?" element={
          <div className="container-fluid">
            <Header />
            <Contact />
            <Footer />
          </div>
          } />
        <Route path="/espace_client" element={
          <div className="container-fluid">
            <Header />
            <EspacaceClient />
            <Footer />
          </div>
        } />
        <Route path="/espace_admin" element={
          <div className="container-fluid">
            <Header />
            <EspaceAdmin />
            <Footer />
          </div>
        } />
        <Route path="/prospection" element={
          <div className="container-fluid">
            <Header />
            <PrivateRoute allowedRoles={['commercial', 'superAdmin']}>
              <Prospection />
            </PrivateRoute>
            <Footer />
          </div>
        } />
        <Route path="/demos" element={
          <div className="container-fluid">
            <Header />
            <Jeux/>
            <Footer />
          </div>
        } />

        {/*site demo coiffure1 */}
        <Route path="/demo/coiffure1" element={<SalonCoiffureLayout />}>
          <Route path="/demo/coiffure1/" element={<HomeSalonCoiffure1 />} />
          <Route path="/demo/coiffure1/services" element={<HomeSalonCoiffure1 />} />
          <Route path="/demo/coiffure1/produits" element={<HomeSalonCoiffure1 />} />
          <Route path="/demo/coiffure1/galerie" element={<HomeSalonCoiffure1 />} />
          <Route path="/demo/coiffure1/avis" element={<HomeSalonCoiffure1 />} />
          <Route path="/demo/coiffure1/contact" element={<HomeSalonCoiffure1 />} />
          <Route path="/demo/coiffure1/booking" element={<HomeSalonCoiffure1 />} />
        </Route>
      </Routes>
    </Router>
    </AuthProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
