import Carrousel from "../../components/Carrousel";
import "./homeSalonCoiffure1.css"
import img1 from "../../assets/img1.jpg"
import img2 from "../../assets/img2.jpg"


const slides = [
    {
      title: 'Coiffure Élégante',
      text: 'Découvrez les dernières tendances en coiffure pour un look raffiné.',
      image: img1, // Remplace par une URL d'image réelle
    },
    {
      title: 'Style Moderne',
      text: 'Des coupes modernes pour sublimer votre beauté.',
      image: img2, // Remplace par une URL d'image réelle
    },
    // {
    //   title: 'Look Glamour',
    //   text: 'Un look glamour pour toutes les occasions.',
    //   image: 'image3.jpg', // Remplace par une URL d'image réelle
    // },
  ];
  
function HomeSalonCoiffure1(){
    return(
        <main className="main">
            <Carrousel slides={slides} />
        </main>
    )
}
export default HomeSalonCoiffure1