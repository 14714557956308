function AdminNavigation({ ongletActive, setOngletActive }) {
    const tabs = [
        { id: "statistiques", label: "Mes Visites" },
        { id: "clients", label: "Mes Clients" },
        { id: "prospection", label: "Prospection" },
        { id: "factures", label: "Revenus" },
        { id: "messages", label: "Messagerie" },
    ];

    return (
        <nav>
            <h2>Mon Tableau de Bord</h2>
            {tabs.map((tab) => (
                <p
                    key={tab.id}
                    className={ongletActive === tab.id ? "active" : ""}
                    onClick={() => setOngletActive(tab.id)}
                >
                    {tab.label}
                </p>
            ))}
        </nav>
    );
}
export default AdminNavigation