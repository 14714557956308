import React, { useEffect, useState,useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', color:"black" }}>
                <p className="label">{`Date: ${label}`}</p>
                <p className="intro">{`Visiteurs uniques: ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};
function StatisticsChart() {
    const [statistics, setStatistics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [offset, setOffset] = useState(0);

    const formatXAxisDate = (dateString) => {
        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('fr-FR', options).format(date); // Formater en français
    };

    const isCurrentWeek = () => {
        const { endDate } = calculateDateRange();
        const today = new Date().toISOString().split('T')[0];
        return endDate >= today;
    };
    const calculateDateRange = useCallback(() => {
        const start = new Date();
        start.setDate(start.getDate() - (start.getDay() === 0 ? 6 : start.getDay() - 1) - offset * 7); // Lundi précédent
        const end = new Date(start);
        end.setDate(start.getDate() + 6); // Dimanche suivant

        return {
            startDate: start.toISOString().split('T')[0],
            endDate: end.toISOString().split('T')[0],
        };
    }, [offset]);

    useEffect(() => {
        
        
        const fetchStatistics = async () => {
            setLoading(true);
            try {
                const { startDate, endDate } = calculateDateRange();
                const response = await fetch('/api/statistics/getWeeklyVisitors', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ siteUrl: 'example.com', startDate, endDate }),
                });

                if (!response.ok) {
                    throw new Error(`Erreur HTTP ${response.status}: ${response.statusText}`);
                }

                const result = await response.json();

                if (result.status === 'success' && Array.isArray(result.data)) {
                    setStatistics(result.data);
                } else {
                    throw new Error('Données inattendues reçues de l\'API.');
                }
            } catch (err) {
                setError(`Erreur lors de la récupération des statistiques: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchStatistics();
    }, [offset,calculateDateRange]);

    if (loading) {
        return <p>Chargement des statistiques...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div>
            <h1>Statistiques hebdomadaires</h1>
            <button onClick={() => setOffset(offset + 1)}>Semaine précédente</button>
            <button onClick={() => offset > 0 && setOffset(offset - 1)} disabled={isCurrentWeek()}>
                Semaine suivante
            </button>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart data={statistics}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" tickFormatter={formatXAxisDate} />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                        type="monotone"
                        dataKey="unique_visitors"
                        stroke="#8884d8"
                        label={{ position: 'top', fill: '#000', fontSize: 18 }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export default StatisticsChart;
