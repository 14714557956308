import React, { useState, useEffect } from "react";
import "./styles.css";

const FadeInText = ({ children, triggerKey, delay = 200 }) => {
  const [isVisible, setIsVisible] = useState(false); // Gérer la visibilité
  const [shouldRender, setShouldRender] = useState(false); // Gérer le rendu initial

  useEffect(() => {
    setShouldRender(false); // Réinitialiser le rendu
    const renderTimeout = setTimeout(() => {
      setShouldRender(true); // Activer le rendu
      setIsVisible(false); // Réinitialiser la visibilité pour l'animation
      const visibleTimeout = setTimeout(() => {
        setIsVisible(true); // Lancer l'animation
      }, delay); // Petit délai pour permettre à l'animation de s'appliquer
      return () => clearTimeout(visibleTimeout);
    }, 0);

    return () => clearTimeout(renderTimeout);
  }, [triggerKey,delay]); // Relancer l'animation uniquement lorsque triggerKey change

  return (
    shouldRender && (
      <div className={`fade-in ${isVisible ? "show" : ""}`}>
        {children}
      </div>
    )
  );
};

export default FadeInText;
