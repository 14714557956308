import React, { useState } from 'react';
import "./formUser.css";

function FormUser({ roleUser, onClientAdded = () => {} }) {
    const [formData, setFormData] = useState({
        nomUser: '',
        prenomUser: '',
        mailUser: '',
        passwordUser: '',
        roleUser: roleUser || 'defaultRole',
    });

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [showForm, setShowForm] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const formBody = new URLSearchParams();
        for (const key in formData) {
            if (formData[key]) {
                formBody.append(key, formData[key]);
            }
        }

        try {
            const response = await fetch('/api/user/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formBody.toString(),
            });

            const result = await response.json();
            if (response.ok) {
                setMessage({ type: 'success', text: 'Utilisateur ajouté avec succès !' });
                setFormData({
                    nomUser: '',
                    prenomUser: '',
                    mailUser: '',
                    passwordUser: '',
                    roleUser: roleUser,
                });
                onClientAdded();
            } else {
                setMessage({ type: 'error', text: result.message || 'Erreur lors de l\'ajout de l\'utilisateur.' });
            }
        } catch (error) {
            setMessage({ type: 'error', text: 'Erreur réseau. Veuillez réessayer.' });
        } finally {
            setIsLoading(false);
            setShowForm(prev => !prev);
        }
    };

    return showForm ? (
        <div className='formUser'>
            <h2>Ajouter un utilisateur</h2>
            {message && (
                <p
                    aria-live="assertive"
                    style={{
                        color: message.type === 'success' ? 'green' : 'red',
                        marginBottom: '1rem',
                    }}
                >
                    {message.text}
                </p>
            )}
            
                <label htmlFor="nomUser">Nom :</label>
                <input
                    type="text"
                    id="nomUser"
                    name="nomUser"
                    value={formData.nomUser}
                    onChange={handleInputChange}
                    required
                />
                <label htmlFor="prenomUser">Prénom :</label>
                <input
                    type="text"
                    id="prenomUser"
                    name="prenomUser"
                    value={formData.prenomUser}
                    onChange={handleInputChange}
                    required
                />
                <label htmlFor="mailUser">Email :</label>
                <input
                    type="email"
                    id="mailUser"
                    name="mailUser"
                    value={formData.mailUser}
                    onChange={handleInputChange}
                    required
                />
                <label htmlFor="passwordUser">Mot de passe :</label>
                <input
                    type="password"
                    id="passwordUser"
                    name="passwordUser"
                    value={formData.passwordUser}
                    onChange={handleInputChange}
                    required
                />
                <button onClick={handleSubmit} disabled={isLoading}>
                    {isLoading ? "Ajout..." : "AJOUTER"}
                </button>
        </div>
    ) : (
        <div className='formUser'>
            <button onClick={() => setShowForm(true)}>NEW USER</button>
        </div>
    );
}

export default FormUser;
