import FormConnexion from "../../components/FormConnexion";
import { useAuth } from "../../context/AuthContext";
import { useState } from "react";
import "./espaceClient.css";

function EspacaceClient() {
    const { userConnected } = useAuth();
    const [ongletActive, setOngletActive] = useState("projet");

    return (
        <main>
            {/* Si l'utilisateur n'est pas connecté, afficher le formulaire de connexion */}
            {!userConnected && (
                <FormConnexion title="CONNEXION CLIENT" />
            )}

            {/* Si l'utilisateur est connecté, afficher le tableau de bord */}
            {userConnected && (
                <div className="dashboard">
                    <h1>BIENVENUE SUR VOTRE ESPACE CLIENT</h1>
                    <nav>
                        <h2>MON TABLEAU DE BORD</h2>
                        <p
                            className={ongletActive === "projet" ? "active" : ""}
                            onClick={() => setOngletActive("projet")}
                        >
                            MON SITE WEB
                        </p>
                        <p
                            className={ongletActive === "factures" ? "active" : ""}
                            onClick={() => setOngletActive("factures")}
                        >
                            MES FACTURES
                        </p>
                        <p
                            className={ongletActive === "messages" ? "active" : ""}
                            onClick={() => setOngletActive("messages")}
                        >
                            MESSAGERIE
                        </p>
                    </nav>

                    {/* Afficher le contenu en fonction de l'onglet actif */}
                    <section>
                        {ongletActive === "projet" && <div>Contenu de la section MON SITE WEB</div>}
                        {ongletActive === "factures" && <div>Contenu de la section MES FACTURES</div>}
                        {ongletActive === "messages" && <div>Contenu de la section MESSAGERIE</div>}
                    </section>
                </div>
            )}
        </main>
    );
}

export default EspacaceClient;
