import React, { useState } from 'react';

function AddVisits() {
    const [formData, setFormData] = useState({
        nbVisitors: '',
        siteUrl: '',
        date: '',
    });
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage(null);

        try {
            const response = await fetch('/api/statistics/simulateVisitsForTest', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (response.ok) {
                setMessage({ type: 'success', text: result.message });
                setFormData({
                    nbVisitors: '',
                    siteUrl: '',
                    date: '',
                });
            } else {
                setMessage({ type: 'error', text: result.message || 'Erreur lors de l\'ajout des visites.' });
            }
        } catch (error) {
            setMessage({ type: 'error', text: 'Erreur réseau. Veuillez réessayer.' });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <h1>Ajouter des visites</h1>
            {message && (
                <p
                    style={{
                        color: message.type === 'success' ? 'green' : 'red',
                        marginBottom: '1rem',
                    }}
                >
                    {message.text}
                </p>
            )}
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="nbVisitors">Nombre de visiteurs :</label>
                    <input
                        type="number"
                        id="nbVisitors"
                        name="nbVisitors"
                        value={formData.nbVisitors}
                        onChange={handleInputChange}
                        required
                        min="1"
                        placeholder="Entrez le nombre de visiteurs"
                    />
                </div>
                <div>
                    <label htmlFor="siteUrl">URL du site :</label>
                    <input
                        type="text"
                        id="siteUrl"
                        name="siteUrl"
                        value={formData.siteUrl}
                        onChange={handleInputChange}
                        required
                        placeholder="Entrez l'URL du site"
                    />
                </div>
                <div>
                    <label htmlFor="date">Date :</label>
                    <input
                        type="date"
                        id="date"
                        name="date"
                        value={formData.date}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Ajout en cours...' : 'Ajouter des visites'}
                </button>
            </form>
        </div>
    );
}

export default AddVisits;
