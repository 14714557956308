import React, { useState, useEffect, useCallback } from "react";
import "./puzzle.css"
function Puzzle() {
  const [nombresPiecesParLigne, setNombresPiecesParLigne] = useState(8);
  const [listeImages, setListeImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [tempsSecondes, setTempsSecondes] = useState(0);
  const [listePieces, setListePieces] = useState([]);
  const [listePiecesMelangees, setListePiecesMelangees] = useState([]);
  const [pieceSelectionnee, setPieceSelectionnee] = useState(null);
  const [blockSize, setBlockSize] = useState(0);

  const initGame = useCallback(() => {
    const pieces = [];
    for (let i = 0; i < nombresPiecesParLigne; i++) {
      for (let j = 0; j < nombresPiecesParLigne; j++) {
        pieces.push({ x: j, y: i });
      }
    }

    setListePieces(pieces);

    const melangees = [...pieces];
    do {
      melangees.sort(() => Math.random() - 0.5);
    } while (isPerfectMatch(pieces, melangees));

    setListePiecesMelangees(gameStarted ? melangees : pieces); // Affiche dans le bon ordre si le jeu n'a pas commencé
    setPieceSelectionnee(null);
  }, [nombresPiecesParLigne, gameStarted]);

  useEffect(() => {
    const images = [];
    for (let i = 1; i <= 24; i++) {
      images.push(require(`../../assets/games/puzzle/${i}.jpg`));
    }
    setListeImages(images);

    const tailleBloc =
      window.innerHeight > window.innerWidth
        ? (window.innerWidth * 0.7) / nombresPiecesParLigne
        : (window.innerHeight * 0.5) / nombresPiecesParLigne;
    setBlockSize(tailleBloc);

    initGame();
  }, [nombresPiecesParLigne, initGame]);

  useEffect(() => {
    if (gameStarted) {
      const interval = setInterval(() => {
        setTempsSecondes((prev) => prev + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [gameStarted]);

  const isPerfectMatch = (pieces, melangees) => {
    return pieces.every((piece, index) => {
      return piece.x === melangees[index].x && piece.y === melangees[index].y;
    });
  };

  const handlePieceClick = (index) => {
    if (!gameStarted) return;

    const piece = listePiecesMelangees[index];
    const targetPiece = listePieces[index];

    // Empêche la sélection des pièces déjà bien placées
    if (piece.x === targetPiece.x && piece.y === targetPiece.y) return;

    if (pieceSelectionnee === null) {
      setPieceSelectionnee(index);
    } else {
      const newPieces = [...listePiecesMelangees];
      [newPieces[pieceSelectionnee], newPieces[index]] = [
        newPieces[index],
        newPieces[pieceSelectionnee],
      ];
      setListePiecesMelangees(newPieces);
      setPieceSelectionnee(null);

      if (isPerfectMatch(listePieces, newPieces)) {
        setGameStarted(false);
        alert(`Puzzle terminé ! Temps : ${convertirSecondes(tempsSecondes)}`);
      }
    }
  };

  const convertirSecondes = (s) => {
    return `${Math.floor(s / 60)}m${s % 60 < 10 ? "0" : ""}${s % 60}s`;
  };

  const handleStartGame = () => {
    setTempsSecondes(0);
    initGame();
    setGameStarted(true);
  };

  const getPieceBorder = (piece, index) => {
    const { x, y } = piece;
    const maxIndex = nombresPiecesParLigne - 1;
    //const isCorner = (x === 0 || x === maxIndex) && (y === 0 || y === maxIndex);
    const isSelected = pieceSelectionnee === index;
    const isPlaced = piece.x === listePieces[index].x && piece.y === listePieces[index].y;
  
    return {
      borderTop: y === 0 ? "4px solid red" : "1px solid black",
      borderBottom: y === maxIndex ? "4px solid red" : "1px solid black",
      borderLeft: x === 0 ? "4px solid red" : "1px solid black",
      borderRight: x === maxIndex ? "4px solid red" : "1px solid black",
      ...(isSelected && {
        borderTop: "4px solid yellow",
        borderBottom: "4px solid yellow",
        borderLeft: "4px solid yellow",
        borderRight: "4px solid yellow",
      }),
      ...(isPlaced && gameStarted &&{
        borderTop: "none",
        borderBottom: "none",
        borderLeft: "none",
        borderRight: "none",
      }),
      cursor: isPlaced ? "default" : "pointer", // Désactive le curseur si bien placée
     // opacity: isPlaced ? 0.6 : 1, // Réduction de l'opacité pour les pièces bien placées
    };
  };
  

  return (
    <div className="App">
      <span>
          Taille : {nombresPiecesParLigne}x{nombresPiecesParLigne}
        </span>
      <div className="puzzle-controls">
      <button
          onClick={() => setImageIndex((prev) => (prev - 1 + listeImages.length) % listeImages.length)}
        >
          Image précédente
        </button>
        <button onClick={() => setNombresPiecesParLigne((prev) => Math.max(prev - 1, 3))}>
          -
        </button>
        
        <button onClick={handleStartGame}>Démarrer le jeu</button>
        <button onClick={() => setNombresPiecesParLigne((prev) => Math.min(prev + 1, 14))}>
          +
        </button>
        <button
          onClick={() => setImageIndex((prev) => (prev + 1) % listeImages.length)}
        >
          Image suivante
        </button>
      
      </div>
      
        
        
      
      <div>Temps : {convertirSecondes(tempsSecondes)}</div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${nombresPiecesParLigne}, ${blockSize}px)`,
          gridGap: "0px",
          margin: "20px auto",
          width: blockSize * nombresPiecesParLigne,
        }}
      >
        {listePiecesMelangees.map((piece, index) => (
          <div
            key={index}
            onClick={() => handlePieceClick(index)}
            style={{
              width: blockSize,
              height: blockSize,
              backgroundImage: `url(${listeImages[imageIndex]})`,
              backgroundPosition: `${-piece.x * blockSize}px ${-piece.y * blockSize}px`,
              backgroundSize: `${blockSize * nombresPiecesParLigne}px ${blockSize * nombresPiecesParLigne}px`,
              ...getPieceBorder(piece, index),
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default Puzzle;
