import FormConnexion from "../../components/FormConnexion";
import { useAuth } from "../../context/AuthContext";
import {useEffect, useState } from "react";
import "./espaceAdmin.css";
import AdminNavigation from "../../components/AdminNavigation";
import FormUser from "../../components/FormUser";
import DataTable from "../../components/DataTable";
import StatisticsChart from "../../components/StatisticsChart";
import AddVisits from "../../components/AddVisits";
import GenerateProspect from "../../components/GenerateProspect";
import FormAppel from "../../components/FormAppel";

function EspaceAdmin() {
    const { userConnected } = useAuth();
    const [ongletActive, setOngletActive] = useState("clients");
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(false); // Ajout pour gérer les rafraîchissements

    const columns = [{title:"NOM CLIENT",data:"nomUser"},{title:"PRENOM CLIENT",data:"prenomUser"},{title:"MAIL CLIENT",data:"mailUser"}]
    const onUpdateData = async (updatedRow) => {
        try {
            // Envoi des données mises à jour au backend via une requête API
            const response = await fetch(`/api/user/update`, {
                method: 'PUT', // Utilisez la méthode HTTP appropriée
                headers: {
                    'Content-Type': 'application/json', // Spécifiez le type de contenu
                },
                body: JSON.stringify(updatedRow), // Convertir les données en JSON
            });
    
            // Vérification de la réponse du serveur
            if (response.ok) {
                const data = await response.json();
    
                // Mise à jour des clients dans l'état
                setClients((prevClients) =>
                    prevClients.map((client) =>
                        client.idUser === updatedRow.idUser ? data.updatedUser : client
                    )
                );
    
                console.log('Utilisateur mis à jour avec succès', data.updatedUser);
            } else {
                console.error('Erreur lors de la mise à jour :', response.statusText);
            }
        } catch (error) {
            console.error('Erreur réseau ou autre :', error);
        }
    };
    
      
    
         
    
        // Fonction pour récupérer les clients
    useEffect(() => {
        if (ongletActive === "clients" && userConnected?.roleUser === "superAdmin") {
            setLoading(true);
            fetch('/api/clients', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userConnected.token}`, // Optionnel si vous utilisez un token
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Une erreur s'est produite lors de la récupération des clients.");
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.status === "success") {
                        setClients(data.data);
                    } else {
                        throw new Error(data.message || "Erreur inconnue.");
                    }
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        }
    }, [ongletActive, userConnected,refresh]);
    return (
        <main>
            {/* Si l'utilisateur n'est pas connecté, afficher le formulaire de connexion */}
            {!userConnected ? (
                <FormConnexion title="CONNEXION ADMINISTRATEUR" />
            ) : userConnected.roleUser !== "superAdmin" ? (
                <div className="access-denied">
                    <h1>Accès Refusé</h1>
                    <p>Vous n'avez pas les droits nécessaires pour accéder à cet espace.</p>
                </div>
            ) : (
                // Si l'utilisateur est un super administrateur, afficher le tableau de bord
                <div className="main-container">
                    <div className="dashboard">
                        
                    <AdminNavigation ongletActive={ongletActive} setOngletActive={setOngletActive} />
                    </div>
                    <section>
                        {ongletActive === "statistiques" &&
                            <div>
                                <StatisticsChart/>
                                <AddVisits/>
                            </div>
                        }
                        {ongletActive === "prospection" &&
                            <div>
                                <GenerateProspect/>
                                <FormAppel/>
                            </div>
                        }
                        {ongletActive === "clients" && 
                            <div>
                            <FormUser 
                                roleUser="client" 
                                onClientAdded={() => setRefresh((prev) => !prev)} // Forcer le rafraîchissement
                            />

                            {loading ? (
                                <p>Chargement des clients...</p>
                            ) : error ? (
                                <p style={{ color: "red" }}>{error}</p>
                            ) : (
                                <DataTable columns={columns} data={clients} onUpdateData = {onUpdateData} />
                            )}
                        </div>}
                        {ongletActive === "factures" && <div>Statistiques de revenus et gestion des factures</div>}
                        {ongletActive === "messages" && <div>Boîte de messagerie pour les interactions avec les clients</div>}
                    </section>
                </div>
            )}
        </main>
    );
}

export default EspaceAdmin;
