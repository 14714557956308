import "./home.css"
import video from "../../assets/futur.mp4"
import { useNavigate, Link } from 'react-router-dom';
import FadeInText from "../../components/FadeInText";
import { Swiper, SwiperSlide } from "swiper/react"; // Import des composants React
import { Navigation, Pagination } from 'swiper/modules';
import React, { useState } from "react";

import "swiper/css"; // Import des styles de base
import "swiper/css/navigation"; // Import des styles pour la navigation
import "swiper/css/pagination"; // Styles pour la pagination

function Home() {
    const navigate = useNavigate();
    const [activeSlideKey, setActiveSlideKey] = useState(0); // Clé pour déclencher l'animation

  const handleRedirect = () => {
    navigate('/contact'); // Redirige vers la page de contact
  };
    return (
        <main>
            <div className="video-container col-12">
            <video autoPlay muted loop>
                <source src={video} type="video/mp4" />
            </video>
                <FadeInText>
                
                <div className="caption" id="description">
                    <h1>CONCEPTION DE VOTRE SITE WEB SUR MESURE<br/> A PARTIR DE 49€90 PAR MOIS <br/> 07 . 66 . 79 . 70 . 02</h1>
                    <p>Je réalise votre site de A à Z, de l'élaboration du cahier des charges à la mise en ligne</p>
                    
                </div>
                
                </FadeInText>
                
            </div>
            <div id="presentation">
                        <FadeInText >
                            <h1>Bienvenue chez Bonnard Web Editions</h1>
                            <p> Bienvenue chez Bonnard Web Editions, micro entreprise spécialisée dans la création, le référencement, la maintenance et la refonte graphique de sites et applications Web.</p>
                            <p>Je suis à votre écoute pour réaliser avec vous votre projet au meilleur prix.</p>
                            <p>N'hésitez pas à <Link to="/offres"> consulter les offres disponibles</Link> et à me <Link to="/contact">contacter via le formulaire</Link>, je me ferai une joie de vous aider à réaliser votre projet.</p>
                        </FadeInText>
                    </div>
            <Swiper
                    rewind={true}
                    navigation={true}
                    pagination={{ clickable: true, type: 'bullets' }}
                    spaceBetween={50}
                    slidesPerView={1}
                    onSlideChange={(swiper) => setActiveSlideKey(swiper.activeIndex)} // Détecte le changement de slide
                    modules={[Navigation,Pagination]} // Modules activés
                >
                
            
                <SwiperSlide>
                <div className="benefit-item">
                <FadeInText triggerKey={activeSlideKey}  delay={1000}>
                        <h2>Pourquoi faire appel à mes services ?</h2>
                        <p>De nombreux prestataires dans le domaine de la création de sites web utilisent des CMS (systèmes de gestion de contenu) tels que WordPress. Bien que ces outils permettent de créer des sites relativement rapidement, ils reposent sur des modèles et des plugins préexistants, ce qui ne nécessite aucune réelle compétence en développement informatique. Ces solutions peuvent être limitées, génériques et souvent peu adaptées aux besoins spécifiques de chaque projet.</p>
                        <p>À l’inverse, je vous propose une approche personnalisée, où chaque site est conçu sur mesure en utilisant <strong>React</strong>, un framework JavaScript moderne et performant. Voici pourquoi faire appel à mes services vous garantit une solution plus complète et de meilleure qualité :</p>
                    </FadeInText>
                </div>
                    
                </SwiperSlide>
                <SwiperSlide>
                    <div className="benefit-item">
                        <FadeInText triggerKey={activeSlideKey}>
                            <h3>1. <strong>Personnalisation Totale et Unique</strong></h3>
                            <p>En optant pour un développement personnalisé avec React, je crée un site web totalement sur mesure, adapté à vos besoins précis. Contrairement aux solutions CMS qui imposent un design ou des fonctionnalités fixes, je suis en mesure de créer une interface unique, en parfaite adéquation avec votre identité visuelle et vos attentes. Chaque fonctionnalité est pensée et développée spécifiquement pour vous, offrant ainsi un site qui ne ressemble à aucun autre.</p>
                        </FadeInText>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="benefit-item">
                        <FadeInText triggerKey={activeSlideKey}>
                            <h3>2. <strong>Meilleure Performance et Vitesse de Chargement</strong></h3>
                            <p>Un site conçu avec React est conçu pour être extrêmement rapide. Grâce à son architecture basée sur les composants et à son rendu efficace, React charge uniquement ce qui est nécessaire pour l'utilisateur, sans surcharger la page. Contrairement aux CMS, souvent alourdis par des plugins et des éléments inutiles, mes sites sont optimisés pour une vitesse de chargement maximale, ce qui améliore l'expérience utilisateur et booste votre référencement sur Google.</p>
                        </FadeInText>
                    </div>
                </SwiperSlide>    
                <SwiperSlide>
                    <div className="benefit-item">
                        <FadeInText triggerKey={activeSlideKey} >
                            <h3>3. <strong>Sécurité Accrue</strong></h3>
                            <p>Les sites réalisés avec React sont intrinsèquement plus sécurisés, car ils ne dépendent pas de plugins tiers qui peuvent être vulnérables aux attaques. WordPress, par exemple, est souvent la cible de hackers en raison de sa popularité et de l'utilisation massive de plugins qui ne sont pas toujours mis à jour correctement. En créant votre site moi-même, je vous assure une sécurité maximale, en mettant en place des pratiques de codage rigoureuses et en éliminant les risques liés aux outils tiers.</p>
                        </FadeInText>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="benefit-item">
                        <FadeInText triggerKey={activeSlideKey}>
                            <h3>4. <strong>Flexibilité et Scalabilité</strong></h3>
                            <p>Avec React, chaque aspect du site peut être modifié ou étendu à tout moment. Si vous souhaitez ajouter de nouvelles fonctionnalités ou faire évoluer votre site au fur et à mesure de vos besoins, la mise à jour devient facile et rapide. Les CMS, quant à eux, peuvent devenir rigides ou difficiles à faire évoluer, et peuvent nécessiter de nombreux ajustements pour intégrer des fonctionnalités spécifiques. Avec React, votre site grandit avec votre entreprise sans compromis.</p>
                        </FadeInText>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="benefit-item">
                        <FadeInText triggerKey={activeSlideKey}>
                            <h3>5. <strong>Contrôle Complet sur le Code</strong></h3>
                            <p>En codant moi-même chaque ligne de code, je vous garantis une transparence totale. Vous avez un contrôle total sur le code source, ce qui vous permet de mieux gérer et de maintenir votre site à long terme. Vous n’êtes pas dépendant d’un CMS dont les mises à jour ou les modifications de plugins peuvent affecter la stabilité de votre site. Vous avez ainsi la tranquillité d’esprit en sachant que le site est conçu avec les meilleures pratiques du développement web moderne.</p>
                        </FadeInText>
                    </div>
                </SwiperSlide>    
                <SwiperSlide>
                    <div className="benefit-item">
                        <FadeInText triggerKey={activeSlideKey}>
                            <h3>6. <strong>Meilleure Expérience Utilisateur (UX)</strong></h3>
                            <p>React permet de créer des interfaces utilisateurs interactives et fluides. Cela signifie que vos visiteurs profiteront d'une expérience plus agréable grâce à des transitions douces, une navigation rapide et des fonctionnalités dynamiques. Vous pouvez offrir à vos utilisateurs une interface moderne qui répond instantanément à leurs besoins, sans les frustrer avec des temps de chargement ou des interactions lentes. L’expérience utilisateur est un facteur crucial pour fidéliser vos visiteurs, et je m’assure qu’elle soit optimale.</p>
                        </FadeInText>
                    </div>
                </SwiperSlide>    
                <SwiperSlide>
                    <div className="benefit-item">
                        <FadeInText triggerKey={activeSlideKey}>
                            <h3>7. <strong>Optimisation SEO Avancée</strong></h3>
                            <p>Avec un site React, vous avez un contrôle total sur l’optimisation SEO. Bien que WordPress propose des plugins de référencement, ceux-ci ne sont pas toujours adaptés à vos besoins spécifiques et peuvent parfois ralentir le site. Avec React, je peux optimiser chaque page, chaque URL et chaque contenu afin d’assurer un référencement naturel efficace et performant. Le chargement rapide, le code léger et la structure optimisée pour le SEO vous garantissent une meilleure visibilité sur Google et un meilleur classement.</p>
                        </FadeInText>
                    </div>
                </SwiperSlide>    
                <SwiperSlide>
                    <div className="benefit-item">
                        <FadeInText triggerKey={activeSlideKey} >
                            <h3>8. <strong>Indépendance vis-à-vis des Outils Externes</strong></h3>
                            <p>Avec React, vous n'êtes pas dépendant d'un CMS, de thèmes préconçus ou de plugins tiers. Cela réduit considérablement les risques de bugs, d’incompatibilités et de mises à jour de sécurité manquantes. Vous êtes totalement maître de votre site, et vous n'avez pas à vous soucier de la gestion de plugins ou de l'impact de la mise à jour d'un outil externe sur votre site. Vous bénéficiez d’un site unique, sans aucune dépendance extérieure.</p>
                        </FadeInText>
                    </div>    
                </SwiperSlide>    
                <SwiperSlide>
                    <div className="benefit-item">
                        <FadeInText triggerKey={activeSlideKey}>
                            <h3>Conclusion : <strong>Une Solution Moderne, Flexible et Sur-Mesure</strong></h3>
                            <p>En choisissant de travailler avec moi, vous optez pour un site web qui va au-delà des limites des CMS traditionnels. Vous bénéficiez d’un développement moderne, sur mesure, sécurisé et optimisé pour la performance. Mon expertise en React vous assure une solution de qualité, évolutive et parfaitement adaptée à vos besoins. Vous n'êtes pas juste un client parmi tant d'autres, mais un partenaire avec qui je collabore pour créer un site qui vous ressemble.</p>
                            <p>Alors, pourquoi se contenter d'une solution générique quand vous pouvez avoir un site web unique, conçu spécialement pour vous et optimisé dans les moindres détails ?</p>
                            <button onClick={handleRedirect}>CONTACTEZ MOI</button>
                        </FadeInText>
                    </div>
                </SwiperSlide>    
                
                    
                    
            
            
            </Swiper>
        </main>
    )
}

export default Home
