import { NavLink } from "react-router-dom"
import "./headerSalonCoiffure1.css"
function HeaderSalonCoiffure1(){
    return(
        <header>
            <div className="booking-banner">
                <button>PRENDRE UN RENDEZ-VOUS</button>
            </div>
            <nav className="main-nav">
                <NavLink className="main-nav-item" to="/demo/coiffure1/" end>ACCUEIL</NavLink>
                <NavLink className="main-nav-item" to="/demo/coiffure1/services">SERVICES</NavLink>
                <NavLink className="main-nav-item" to="/demo/coiffure1/produits">PRODUITS</NavLink>
                <NavLink className="main-nav-item" to="/demo/coiffure1/galerie">GALERIE</NavLink>
                <NavLink className="main-nav-item" to="/demo/coiffure1/avis">AVIS CLIENT</NavLink>
                <NavLink className="main-nav-item" to="/demo/coiffure1/contact">CONTACT</NavLink>
                <NavLink className="main-nav-item" to="/demo/coiffure1/booking">RESERVATION</NavLink>
            </nav>
        </header>
        
    )
}
export default HeaderSalonCoiffure1