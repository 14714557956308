import React, { useState } from "react";
import "./starRating.css";

const StarRating = ({ onRatingSubmit, ratingProp = 0, readOnly = false }) => {
  const [rating, setRating] = useState(ratingProp); // État pour la note sélectionnée
  const [hover, setHover] = useState(0); // État pour l'étoile en survol

  const handleClick = (rate) => {
    if (!readOnly) {
      setRating(rate); // Mettre à jour la note sélectionnée
      onRatingSubmit && onRatingSubmit(rate); // Appel de la fonction de soumission si elle existe
    }
  };

  return (
    <div className="stars">
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;
        return (
          <label key={index}>
            {!readOnly && (
              <input
                type="radio"
                name="rating"
                className="hidden"
                value={ratingValue}
                onClick={() => handleClick(ratingValue)}
              />
            )}
            <svg
              className="star"
              width="30"
              height="30"
              fill={ratingValue <= (hover || rating) ? "#ffdd00" : "#ccc"}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              onMouseEnter={() => !readOnly && setHover(ratingValue)} // Survoler pour changer la couleur temporairement
              onMouseLeave={() => !readOnly && setHover(0)} // Remettre à zéro après le survol
            >
              <path d="M12 .587l3.668 7.431 8.2 1.191-5.924 5.774 1.398 8.155L12 18.897l-7.342 3.86 1.398-8.155L.132 9.209l8.2-1.191z" />
            </svg>
          </label>
        );
      })}
    </div>
  );
};

export default StarRating;
